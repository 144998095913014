import {SiteLinkList} from "./SitelinkList";
import {SiteLinkShow} from "./Sitelinks";
import {SitelinkEdit} from "./SitelinkEdit";
import {SiteLinkCreate} from "./SiteLinkCreate";


export default {
    list: SiteLinkList,
    show: SiteLinkShow,
    create:SiteLinkCreate,
    edit:SitelinkEdit,
    icon:<img style={{width:"23px"}} src={"https://cdn.scanbuy.com/apps/cmp-v2/images/Website_URL.png"}/>
}
