import { embedDashboard } from "@superset-ui/embedded-sdk";
import {apiUrl, httpClient} from "../api";
import {useEffect, useState} from "react";
import config from "../configs";

export const SummaryAnalytics = () => {

    const fetchGuestToken = async () => {
        //TODO: Fetch from the API,  /me/qr/codes/uuid/reports/embeddedReport
        const res = await httpClient(`${apiUrl}/reports/${config.ANALYTICS_SUMMARY_REPORT_UUID}/embeddedToken`, { method: 'GET'});
        console.log("token");
        console.log(res);
        return res.json.token;
    }

    const  init =  async () => {
        let dashboard = await embedDashboard({
            id: '87b4992b-07f8-4759-a843-9094f860e0fb',
            supersetDomain: config.ANALYTICS_DOMAIN,
            mountPoint: document.getElementById("superset"),
            fetchGuestToken: fetchGuestToken,
            dashboardUiConfig: {
                hideTitle: true,
                hideChartControls: false,
                hideTab: false
            },
            filters: {
                expanded: true,
            }
        })

        dashboard.getScrollSize()?.then((size) => {
            try {
                const { width, height } = size;
                console.log(size)
                console.log(`${width} x ${height}`);
                // setWidth(900);
                // setHeight(height);

                document.getElementById("superset").firstChild.style.height = "100%";
                document.getElementById("superset").firstChild.style.width = "100%";
                document.getElementById("superset").firstChild.style.overflow = "hidden";
                document.getElementById("superset").firstChild.style.border = "none";
            } catch (e) {
                console.error(e);
            }
        }).catch( (ex) =>{

        })
    }
    useEffect(() => {   init(); }, []);

    return (
        <div id="superset" style={{width:"100%",height:"900px"}}/>
    )
}