
const configurationFile = {
    production: {
        AUTH0_DOMAIN: "auth.scanbuy.com",
        AUTH0_CLIENT_ID: "KxKBlUgJfjZWK6afiWY23tNpNoHi0DxA",
        AUTH0_AUD: "https://scanbuyportal.auth0.com/api/v2/",
        AUTH0_CALLBACK: "https://axalta.scanbuy.com",
        DOMAIN_NAME: "https://axalta.scanbuy.com",
        API_DOMAIN_NAME: "https://api.scanbuy.com/v2/me",
        SUPERSET_API_DOMAIN: "https://analytics.scanbuy.com",
        IMAGE_API_DOMAIN_NAME: "https://api.scanbuy.com/media",
        X_API_KEY: "hboKwoEiLY3Ib00T18uUk610hHizenDH5bxpCFmf",
        X_API_KEY_IMAGE: "1AU7oLt0DObOvQ07NAcP4UbeXOa9V0x3EL0jbgR4",
        META_FILE: "./meta.json",
        LOGROCKET_APP_ID: 'oj3ojm/axalta',
        PORTAL_URL: "https://axalta.scanbuy.com/",
        TENANT_UUID: "6GPrCEECbScNRmDVyzP5yn",
        LOGOUT_URL: "https://auth.scanbuy.com/v2/logout?federated&returnTo=https%3A%2F%2Fscanbuy.com&client_id=jVTIciJATIp0xBTOiMfMRm8W3GJFcfyX",
        ANALYTICS_DOMAIN: "https://analytics.scanbuy.com",
        ANALYTICS_SUMMARY_REPORT_UUID: "66073402-c354-4a8a-89ac-779d3b681ac5"
    },
    staging: {
        // AUTH0_DOMAIN: "scanbuyportal-stg.auth0.com",
        // AUTH0_CLIENT_ID: "j6X1n2FCHGIKOHy55PC0FOcdiqYSyyWA",
        // AUTH0_AUD: "https://scanbuyportal-stg.auth0.com/api/v2/",
        // AUTH0_CALLBACK: "https://stgportal.scanbuy.com/pages",
        // DOMAIN_NAME: "https://stgportal.scanbuy.com/",
        // API_DOMAIN_NAME: "https://stgportal.scanbuy.com/api/menu",
        // IMAGE_API_DOMAIN_NAME: "https://stgapi.scanbuy.com/media",
        // X_API_KEY: "9DU9BNIu0y7ejuSnL4mML2M5551sNqkZ1qLuRq6d",
        // X_API_KEY_IMAGE: "PNXptlNFnN8nttEzpsRyR3IJ63wuhHbr9eCy8ltH",
        // META_FILE: "/pages/meta.json",
        // LOGROCKET_APP_ID: 'oj3ojm/scanbuy'
        // TENANT_UUID: "qxYsuUjc45uoiy2EMJp8Bq",
        // ANALYTICS_DOMAIN: "https://analytics.scanbuy.com",
        // ANALYTICS_SUMMARY_REPORT_UUID: ""

    },
    test: {
        // AUTH0_DOMAIN: "scanbuyportal-test.auth0.com",
        // AUTH0_CLIENT_ID: "XqpSwuCi7ktQzQgVv1Es2xliIDrweRx3",
        // AUTH0_AUD: "https://scanbuyportal-test.auth0.com/api/v2/",
        // AUTH0_CALLBACK: "https://testportal.scanbuy.com/pages",
        // DOMAIN_NAME: "https://testportal.scanbuy.com/menus",
        // API_DOMAIN_NAME: "https://testapi.scanbuy.com/pages",
        // IMAGE_API_DOMAIN_NAME: "http://10.0.0.245:3100",
        // X_API_KEY: "",
        // META_FILE: "/pages/meta.json",
        //LOGROCKET_APP_ID: 'oj3ojm/scanbuy',
        // ANALYTICS_DOMAIN: "https://analytics.scanbuy.com",
        // ANALYTICS_SUMMARY_REPORT_UUID: ""

    },
    development: {
        AUTH0_DOMAIN: "scanbuyportal-dev.auth0.com",
        AUTH0_CLIENT_ID: "rxUVC1iB5FULP4ihy6cbhOkqiLIlEv2Y",
        AUTH0_AUD: "https://scanbuyportal-dev.auth0.com/api/v2/",
        AUTH0_CALLBACK: "https://devcmp.scanbuy.com",
        DOMAIN_NAME: "https://devcmp.scanbuy.com",
        API_DOMAIN_NAME: "https://devapi2.scanbuy.com/v2",
        SUPERSET_API_DOMAIN: "https://analytics.scanbuy.com",
        IMAGE_API_DOMAIN_NAME: "https://devapi.scanbuy.com/media",
        X_API_KEY: "tWAh433UMB6gFVSfKthEc6B9K66vMKNQ1a4MeMkN",
        X_API_KEY_IMAGE: "wYgPJ31v9x3wvYxRj4MeR12CkGm8nvZh9bvggLrL",
        META_FILE: "./meta.json",
        LOGROCKET_APP_ID: 'oj3ojm/scanbuy',
        PORTAL_URL: "https://devportal.scanbuy.com/",
        TENANT_UUID: "qxYsuUjc45uoiy2EMJp8Bq",
        LOGOUT_URL: "https://scanbuyportal-dev.auth0.com/v2/logout?federated&returnTo=https%3A%2F%2Fscanbuy.com&client_id=rxUVC1iB5FULP4ihy6cbhOkqiLIlEv2Y",
        ANALYTICS_DOMAIN: "https://analytics.scanbuy.com",
        ANALYTICS_SUMMARY_REPORT_UUID: ""
    },
    local: {
        AUTH0_DOMAIN: "scanbuyportal-local.auth0.com",
        AUTH0_CLIENT_ID: "HvWEtZk7vK4je07CswbgMsaB8kdUbVqT",
        AUTH0_AUD: "https://scanbuyportal-local.auth0.com/api/v2/",
        AUTH0_CALLBACK: "http://localhost:3000",
        DOMAIN_NAME: "http://127.0.0.1:3000",
        API_DOMAIN_NAME: "http://localhost:8881/me",
        SUPERSET_API_DOMAIN: "http://localhost:8088",
        IMAGE_API_DOMAIN_NAME: "http://localhost:4003/local",
        X_API_KEY: "tWAh433UMB6gFVSfKthEc6B9K66vMKNQ1a4MeMkN",
        X_API_KEY_IMAGE: "123",
        META_FILE: "./meta.json",
        LOGROCKET_APP_ID: 'oj3ojm/scanbuy',
        PORTAL_URL: "http://localhost:3000/",
        TENANT_UUID: "qxYsuUjc45uoiy2EMJp8Bq",
        LOGOUT_URL: "https://scanbuyportal-local.auth0.com/v2/logout?federated&returnTo=https%3A%2F%2Fscanbuy.com&client_id=HvWEtZk7vK4je07CswbgMsaB8kdUbVqT",
        ANALYTICS_DOMAIN: "https://analytics.scanbuy.com",
        ANALYTICS_SUMMARY_REPORT_UUID: "66073402-c354-4a8a-89ac-779d3b681ac5"

    }
};
const CURRENT_ENV = process.env["REACT_APP_ENV"] || "local";

export default configurationFile[CURRENT_ENV];

