import {
    CreateButton,
    Datagrid,
    DateField, DateInput, DeleteButton, downloadCSV, EditButton, ExportButton,
    FilterButton,
    List,
    SearchInput, SelectInput,
    TextField,
    TopToolbar,
    UrlField
} from "react-admin";
import * as React from "react";
import {ShortUrl} from "../sitelinks/Sitelinks";
import jsonExport from 'jsonexport/dist';

import {IOSIcon, AndroidIcon, LinkIcon} from "./utils";

const ListActions = () => (
    <TopToolbar>
        <FilterButton/>
        <ExportButton/>
        <CreateButton/>
        {/*<ExportButton/>*/}
        {/* Add your custom actions */}
        {/*<Button*/}
        {/*    onClick={() => { alert('Your custom action'); }}*/}
        {/*    label="Show calendar"*/}
        {/*>*/}
        {/*    <IconEvent/>*/}
        {/*</Button>*/}
    </TopToolbar>
);



const applinkFilters = [
    <SearchInput name="search" source="q" alwaysOn />,
    <SelectInput
        name="status"
        source="status"
        choices={[
            { id: 'DRAFT', name: 'DRAFT' },
            { id: 'PUBLISHED', name: 'PUBLISHED' }
        ]}
    />,
    <DateInput name="updatedAt" source="updatedAt" />,
    <DateInput name="createdAt" source="createdAt" />,
];
const exporter = apps => {
    const appsForExport = apps.map(app => {
        const {  data,id,_id,uuid, images, name,_audit,
            utm,shortUrl,statusDetail,createdAt,updatedAt,
            owner,type,status,_tenantUuid,tags,

            ...appForExport } = app;

        appForExport.name = app?.name ?? "";
        appForExport.ios_url = app?.data?.appStore ?? "";
        appForExport.android_url = app?.data?.playStore ?? "";
        appForExport.fallback_url = app?.data?.fallback ?? "";
        appForExport.short_url = (app?.shortUrl?.domain && app?.shortUrl?.uri) ?
            app?.shortUrl?.domain + "/" + app?.shortUrl?.uri : "";
        appForExport.status = app?.status ?? ""
        appForExport.updated_at =app?.updatedAt ? new Date(app?.updatedAt):"";
        appForExport.created_at = app?.createdAt ? new Date(app?.createdAt):"";

        return appForExport;
    });
    jsonExport(appsForExport, {
        headers: ["name",'ios_url',"android_url","fallback_url","short_url","status","updated_at","created_at"]
    }, (err, csv) => {
        downloadCSV(csv, 'axalta_apps'); // <-- name download file here
    });
};

export const AppLinkList = () => (
    <List
        exporter={exporter}
        filters={applinkFilters}
        actions={<ListActions/>}
    >
        <Datagrid rowClick="show"
                  optimized
        >
            <TextField source="name" />
            <UrlField source="data.appStore" label="iOS Url"/>
            <UrlField source="data.playStore" label="Android Url"/>
            <UrlField source="data.fallback" label="Fallback Url"/>
            <ShortUrl source="shortUrl" label="Short Url"/>
            <TextField source="status" />
            <DateField source="updatedAt" />
            <DateField source="createdAt" />
            <EditButton/>
            <DeleteButton mutationMode="pessimistic"  />
        </Datagrid>
    </List>
)
