import {fetchUtils, NumberInput, TextInput, useGetList, useRecordContext} from "react-admin";
import * as React from "react";
import {CircularProgress, Divider, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import scnqr from "../assets/images/scnqr.png";
// import {triggerBase64Download} from "react-base64-downloader";
import {saveAs} from "file-saver"
import TextField from '@mui/material/TextField';
import {useEffect} from "react";




export const ImageConfigField = () => {
    const record = useRecordContext();
    const { data , isLoading } = useGetList('qr/image/configs');
    // const [selectedDesign, setSelectedDesign] = React.useState(null);
    const [image, setImage] = React.useState(record?.qrPreview);
    const [imageFormat, setImageFormat] = React.useState("png");
    const [size, setSize] = React.useState(1.0);
    const [resolution, setResolution] = React.useState(1200);
    const [downloading, setDownloading] = React.useState(false);
    const [readyForDownload, setReadyForDownload] = React.useState(false);
    const [selectedDesign, setSelectedDesign] = React.useState("");
    const [designLoading, setDesignLoading] = React.useState(false);

    const generateImage = async (design, format,size,resolution) => {
        setDesignLoading(true);
        setSelectedDesign(design);
        const url = record.shortUrl.domain + "/" + record.shortUrl.uri;
        const selectedData = data.filter((d) => d.id === design);
        const d = {
            "colorMode": format==="jpg"?"cmyk":"rgb",
            "backgroundColor":"#ffffff",
            ...JSON.parse(selectedData[0].data),"imageFormat": format, "size": size, "resolution": resolution}
        d.data = url;
        try {
            const res = await  fetchUtils.fetchJson(`https://nigiu8dgm4.execute-api.us-east-2.amazonaws.com/dev/qr`, {
                method: 'POST',
                body: JSON.stringify(d)
            })
            if (res.status === 201) {
                let temp = res.json;
                temp = temp.replace("b'","");
                temp = temp.replace("'","");
                setImage(`data:image/${format};base64,`+temp);
                console.log(image);
                setDesignLoading(false);
                return `data:image/${format};base64,`+temp
            }
        } catch (e) {
            console.error("error", e);
            setDesignLoading(false);
            return null
        }
    }
    useEffect(() => {
        if(imageFormat && size>=1 && resolution && image){
            setReadyForDownload(true);
        }else{
            setReadyForDownload(false);
        }
    }, [imageFormat,resolution,size,image]);
    return (
        <div>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Qr Design</InputLabel>
                <Select
                    labelId="qr-image-config-select-label"
                    id="qr-image-config-select"
                    value={selectedDesign}
                    label="Design"
                    onChange={(e)=>{
                        generateImage(e.target.value, imageFormat, size, resolution);
                    }}
                    displayEmpty
                >
                    {!isLoading && data.map((item) => (
                        <MenuItem value={item.uuid} key={item.uuid}>{item.name}</MenuItem>
                    ))}
                </Select>
                {(designLoading) &&
                    <div style={{
                        width: "100%",
                        height: "127%",
                        position: "absolute",
                        zIndex: "1",
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <div >
                            <CircularProgress color="inherit" />

                        </div>
                    </div>
                }
                {(!image )  && <center><img  src={scnqr} style={{height:"150px", width:"150px",filter:"blur(3px)"}}/></center> }
                {image && <center><img src={image} style={{height:"150px", width:"150px"}}/></center> }
            </FormControl>
            <Divider/>
            <h3>Download</h3>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Format</InputLabel>
                <Select
                    onChange={(e)=>{
                        setImageFormat(e.target.value);
                    }}
                    displayEmpty
                    value={imageFormat}
                    sx={{ width: '100%' }}
                >
                    <MenuItem value={"png"} key={"png"}>PNG</MenuItem>
                    <MenuItem value={"jpg"} key={"jpg"}>JPG</MenuItem>
                    <MenuItem value={"gif"} key={"gif"}>GIF</MenuItem>
                </Select>
                <TextField
                    id="outlined-number"
                    label="Size (Inches)"
                    type="number"
                    InputProps={{
                        inputProps: { min: 1, max: 10 }
                    }}
                    value={size}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e)=>{ setSize(parseInt(e.target.value));}}
                    style={{width:"40%",float:"left"}}
                />
                <TextField
                    id="resolution"
                    label="Resolution"
                    type="number"
                    value={resolution}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        inputProps: { min: 72, max: 2000 }
                    }}
                    onChange={(e)=>{ setResolution(parseInt(e.target.value));}}
                    style={{width:"40%",float:"left"}}
                />
                <LoadingButton
                    variant="contained"
                    disabled={!readyForDownload}
                    onClick={async ()=>  {
                        setDownloading(true);
                        const res = await generateImage(selectedDesign, imageFormat, size, resolution);
                        setDownloading(false)
                        if(res) {
                            setDownloading(true);
                            console.log(record);
                            let downloadName = record.name;
                            if(record.type === "vcard") {
                                downloadName = ((record.data.firstName || 'FN') + "_" + record.data.lastName).toLowerCase();
                            }
                            saveAs(res, downloadName)
                            setDownloading(false);
                        }
                    }}
                    loading={downloading}
                >Download</LoadingButton>
            </FormControl>
        </div>
    )

}
