import {
    Create,

    SimpleForm, TabbedForm, TabbedFormTabs,
    TextInput,
} from "react-admin";
import * as React from "react";
import {useEffect, useState} from "react";
import {Box} from "@mui/material";
import {QrPreview} from "../qrpreview/QrPreview";


export const PageTemplateCreate = () => {

    const [previewData, setPreviewData] = useState({
        data: {
            firstName: "John",
            lastName: "John",
            emails: [{email: "John@gmail.com", emailType: "Primary"}],
            phoneNumbers: [{number: "87690867343", phoneType: "Work"}],
            addresses: [{
                addressType: "Work",
                organization: "Google",
                street: "431 Clevand Ave",
                city: "test",
                state: "California",
                zipcode: "10082",
                country: "US"
            }],
            jobTitle: "Software Developer",
            website: "www.google.com",
            profilePicture: "https://cdn.scanbuy.com/assets/images/axalta_logo_red.png",
        },
        dataDetail: {
            "primaryVCardContainer": {
                "fontSize": "",
                "color": "",
                "fontFamily": "Arial",
                "fontWeight": "bold",
                "fontStyle": "normal",
                "textAlign": "left",
                "border": "",
                "borderRadius": "",
                "margin": "0px",
                "backgroundColor": "white"
            },
            "secondaryVCardContainer": {
                "fontSize": "21px",
                "color": "black",
                "fontFamily": "Arial",
                "fontWeight": "normal",
                "fontStyle": "normal",
                "textAlign": "left",
                "borderRadius": "0px",
                "paddingBottom": "15px",
                "margin": "0px",
                "backgroundColor": "white",
                "marginTop": "0px",
                "paddingBlockStart": "0px",
                "border": "0px solid teal"
            },
            "imageVCardContainer": {
                "width": "128px",
                "height": "128px",
                "fit": "cover",
                "marginTop": "15px",
                "textAlign": "left"
            },
            "downloadVCardButtonContainer": {
                "borderRadius": "5px",
                "backgroundColor": "white",
                "paddingTop": "45px",
                "border": "0px solid teal"
            },
            "downloadVCardButtonText": {
                "fontSize": "20px",
                "color": "black",
                "fontWeight": "bold",
                "alignSelf": "center",
                "textTransform": "uppercase",
                "border": "1px solid white",
                "backgroundColor": "lightblue",
                "borderRadius": "10px",
                "textDecoration": "none",
                "padding": "10px"
            },
            "vcardNameText": {
                "fontSize": "30px",
                "color": "black",
                "fontFamily": "Arial",
                "fontWeight": "bold",
                "fontStyle": "normal",
                "textAlign": "left"
            },
            "subtitleText": {
                "fontSize": "20px",
                "color": "black",
                "fontFamily": "Arial",
                "fontWeight": "bold",
                "fontStyle": "normal",
                "textAlign": "left",
                "marginBottom": "0px"
            },
            "phoneNumberText": {
                "fontSize": "20px",
                "color": "black",
                "fontFamily": "Arial",
                "fontStyle": "normal",
                "textAlign": "left",
                "marginBottom": "0px",
                "paddingTop": "25px"
            }
        }
    });

    const handleOnChange = (containerKey, fieldName, fieldValue) => {
        setPreviewData(prevState => {
            const newDataDetail = {...prevState.dataDetail};
            newDataDetail[containerKey] = {
                ...newDataDetail[containerKey],
                [fieldName]: fieldValue
            };
            return {...prevState, dataDetail: newDataDetail};
        });
    };


    return (
        <Create resource="qrpagetemplates">
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <Box gridColumn="span 8">
                    <TabbedForm tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" />} record={{pageType: "vcard"}} warnWhenUnsavedChanges>
                        <TabbedForm.Tab label="Details">
                            <TextInput source="name" style={{width: '50%'}} isRequired/>
                        </TabbedForm.Tab>
                        <TabbedForm.Tab label="Primary Vcard Containter">
                            <TextInput source="configs.primaryVCardContainer.fontSize" label="Primary Font Size"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('primaryVCardContainer', 'fontSize', e.target.value)}/>
                            <TextInput source="configs.primaryVCardContainer.color" label="Primary Font Color"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('primaryVCardContainer', 'color', e.target.value)}/>
                            <TextInput source="configs.primaryVCardContainer.fontFamily" label="Primary Font Family"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('primaryVCardContainer', 'fontFamily', e.target.value)}/>
                            <TextInput source="configs.primaryVCardContainer.fontWeight" label="Primary Font Weight"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('primaryVCardContainer', 'fontWeight', e.target.value)}/>
                            <TextInput source="configs.primaryVCardContainer.fontStyle" label="Primary Font Style"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('primaryVCardContainer', 'fontStyle', e.target.value)}/>
                            <TextInput source="configs.primaryVCardContainer.textAlign" label="Primary Text Alignment"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('primaryVCardContainer', 'textAlign', e.target.value)}/>
                            <TextInput source="configs.primaryVCardContainer.border" label="Primary Border"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('primaryVCardContainer', 'border', e.target.value)}/>
                            <TextInput source="configs.primaryVCardContainer.borderRadius" label="Primary Border Radius"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('primaryVCardContainer', 'borderRadius', e.target.value)}/>
                            <TextInput source="configs.primaryVCardContainer.margin" label="Primary Margin"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('primaryVCardContainer', 'margin', e.target.value)}/>
                            <TextInput source="configs.primaryVCardContainer.backgroundColor"
                                       label="Primary Background Color" style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('primaryVCardContainer', 'backgroundColor', e.target.value)}/>
                        </TabbedForm.Tab>
                        <TabbedForm.Tab label="Secondary Vcard Containter">
                            <TextInput source="configs.secondaryVCardContainer.fontSize" label="Secondary Font Size"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('secondaryVCardContainer', 'fontSize', e.target.value)}/>
                            <TextInput source="configs.secondaryVCardContainer.color" label="Secondary Font Color"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('secondaryVCardContainer', 'color', e.target.value)}/>
                            <TextInput source="configs.secondaryVCardContainer.fontFamily" label="Secondary Font Family"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('secondaryVCardContainer', 'fontFamily', e.target.value)}/>
                            <TextInput source="configs.secondaryVCardContainer.fontWeight" label="Secondary Font Weight"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('secondaryVCardContainer', 'fontWeight', e.target.value)}/>
                            <TextInput source="configs.secondaryVCardContainer.fontStyle" label="Secondary Font Style"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('secondaryVCardContainer', 'fontStyle', e.target.value)}/>
                            <TextInput source="configs.secondaryVCardContainer.textAlign"
                                       label="Secondary Text Alignment"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('secondaryVCardContainer', 'textAlign', e.target.value)}/>
                            <TextInput source="configs.secondaryVCardContainer.border" label="Secondary Border"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('secondaryVCardContainer', 'border', e.target.value)}/>
                            <TextInput source="configs.secondaryVCardContainer.borderRadius"
                                       label="Secondary Border Radius"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('secondaryVCardContainer', 'borderRadius', e.target.value)}/>
                            <TextInput source="configs.secondaryVCardContainer.paddingBottom" label="Secondary Padding"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('secondaryVCardContainer', 'paddingBottom', e.target.value)}/>
                            <TextInput source="configs.secondaryVCardContainer.margin" label="Secondary Margin"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('secondaryVCardContainer', 'margin', e.target.value)}/>
                            <TextInput source="configs.secondaryVCardContainer.backgroundColor"
                                       label="Secondary Background Color" style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('secondaryVCardContainer', 'backgroundColor', e.target.value)}/>
                            <TextInput source="configs.secondaryVCardContainer.marginTop" label="Secondary Margin Top"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('secondaryVCardContainer', 'marginTop', e.target.value)}/>
                            <TextInput source="configs.secondaryVCardContainer.paddingBlockStart"
                                       label="Padding Block Start" style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('secondaryVCardContainer', 'paddingBlockStart', e.target.value)}/>
                            <TextInput source="configs.secondaryVCardContainer.border" label="Border"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('secondaryVCardContainer', 'border', e.target.value)}/>
                        </TabbedForm.Tab>
                        <TabbedForm.Tab label="Image Vcard Container">
                            <TextInput source="configs.imageVCardContainer.width" label="ImageVCardContainer Width"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('imageVCardContainer', 'width', e.target.value)}/>
                            <TextInput source="configs.imageVCardContainer.height" label="ImageVCardContainer Height"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('imageVCardContainer', 'height', e.target.value)}/>
                            <TextInput source="configs.imageVCardContainer.fit" label="ImageVCardContainer Fit"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('imageVCardContainer', 'fit', e.target.value)}/>
                            <TextInput source="configs.imageVCardContainer.textAlign" label="ImageVCardContainer Text Align"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('imageVCardContainer', 'textAlign', e.target.value)}/>
                            <TextInput source="configs.imageVCardContainer.marginTop" label="ImageVCardContainer Margin Top"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('imageVCardContainer', 'marginTop', e.target.value)}/>
                        </TabbedForm.Tab>
                        <TabbedForm.Tab label="Download Button">
                            <TextInput source="configs.downloadVCardButtonContainer.borderRadius"
                                       label="Button Container Border Radius" style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('downloadVCardButtonContainer', 'borderRadius', e.target.value)}/>
                            <TextInput source="configs.downloadVCardButtonContainer.backgroundColor"
                                       label="Button Container Background Color" style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('downloadVCardButtonContainer', 'backgroundColor', e.target.value)}/>
                            <TextInput source="configs.downloadVCardButtonContainer.paddingTop"
                                       label="Button Container Padding Top" style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('downloadVCardButtonContainer', 'paddingTop', e.target.value)}/>
                            <TextInput source="configs.downloadVCardButtonContainer.border" label="Button Container Border"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('downloadVCardButtonContainer', 'border', e.target.value)}/>

                        </TabbedForm.Tab>
                        <TabbedForm.Tab label="Download Button Text">
                            <TextInput source="configs.downloadVCardButtonText.fontSize" label="Button Text Font Size"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('downloadVCardButtonText', 'fontSize', e.target.value)}/>
                            <TextInput source="configs.downloadVCardButtonText.color" label="Button Text Font Color"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('downloadVCardButtonText', 'color', e.target.value)}/>
                            <TextInput source="configs.downloadVCardButtonText.fontWeight" label="Button Text Font Weight"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('downloadVCardButtonText', 'fontWeight', e.target.value)}/>
                            <TextInput source="configs.downloadVCardButtonText.alignSelf" label="Button Text Align Self"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('downloadVCardButtonText', 'alignSelf', e.target.value)}/>
                            <TextInput source="configs.downloadVCardButtonText.textTransform"
                                       label="Button Text Text Transform" style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('downloadVCardButtonText', 'textTransform', e.target.value)}/>
                            <TextInput source="configs.downloadVCardButtonText.border" label="Button Text Border"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('downloadVCardButtonText', 'border', e.target.value)}/>
                            <TextInput source="configs.downloadVCardButtonText.backgroundColor"
                                       label="Button Text Background Color" style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('downloadVCardButtonText', 'backgroundColor', e.target.value)}/>
                            <TextInput source="configs.downloadVCardButtonText.borderRadius"
                                       label="Button Text Border Radius" style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('downloadVCardButtonText', 'borderRadius', e.target.value)}/>
                            <TextInput source="configs.downloadVCardButtonText.textDecoration"
                                       label="Button Text Text Decoration" style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('downloadVCardButtonText', 'textDecoration', e.target.value)}/>
                            <TextInput source="configs.downloadVCardButtonText.padding" label="Button Text Padding"
                                       onChange={(e) => handleOnChange('downloadVCardButtonText', 'padding', e.target.value)}/>
                        </TabbedForm.Tab>
                        <TabbedForm.Tab label="Vcard Name Text">
                            <TextInput source="configs.vcardNameText.fontSize" label="vCard Name Font Size"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('vcardNameText', 'fontSize', e.target.value)}/>
                            <TextInput source="configs.vcardNameText.color" label="vCard Name Font Color"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('vcardNameText', 'color', e.target.value)}/>
                            <TextInput source="configs.vcardNameText.fontFamily" label="vCard Name Font Family"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('vcardNameText', 'fontFamily', e.target.value)}/>
                            <TextInput source="configs.vcardNameText.fontWeight" label="vCard Name Font Weight"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('vcardNameText', 'fontWeight', e.target.value)}/>
                            <TextInput source="configs.vcardNameText.fontStyle" label="vCard Name Font Style"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('vcardNameText', 'fontStyle', e.target.value)}/>
                            <TextInput source="configs.vcardNameText.textAlign" label="vCard Name Text Align"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('vcardNameText', 'textAlign', e.target.value)}/>
                        </TabbedForm.Tab>
                        <TabbedForm.Tab label="Subtitle Text">
                            <TextInput source="configs.subtitleText.fontSize" label="Subtitle Font Size"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('subtitleText', 'fontSize', e.target.value)}/>
                            <TextInput source="configs.subtitleText.color" label="Subtitle Font Color"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('subtitleText', 'color', e.target.value)}/>
                            <TextInput source="configs.subtitleText.fontFamily" label="Subtitle Font Family"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('subtitleText', 'fontFamily', e.target.value)}/>
                            <TextInput source="configs.subtitleText.fontWeight" label="Subtitle Font Weight"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('subtitleText', 'fontWeight', e.target.value)}/>
                            <TextInput source="configs.subtitleText.fontStyle" label="Subtitle Font Style"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('subtitleText', 'fontStyle', e.target.value)}/>
                            <TextInput source="configs.subtitleText.textAlign" label="Subtitle Text Align"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('subtitleText', 'textAlign', e.target.value)}/>
                            <TextInput source="configs.subtitleText.margin" label="Subtitle Text Margin"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('subtitleText', 'margin', e.target.value)}/>
                        </TabbedForm.Tab>
                        <TabbedForm.Tab label="Phone Number Text">
                            <TextInput source="configs.phoneNumberText.fontSize" label="Phone Number Font Size"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('phoneNumberText', 'fontSize', e.target.value)}/>
                            <TextInput source="configs.phoneNumberText.color" label="Phone Number Font Color"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('phoneNumberText', 'color', e.target.value)}/>
                            <TextInput source="configs.phoneNumberText.fontFamily" label="Phone Number Font Family"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('phoneNumberText', 'fontFamily', e.target.value)}/>
                            <TextInput source="configs.phoneNumberText.fontStyle" label="Phone Number Font Style"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('phoneNumberText', 'fontStyle', e.target.value)}/>
                            <TextInput source="configs.phoneNumberText.textAlign" label="Phone Number Text align"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('phoneNumberText', 'textAlign', e.target.value)}/>
                            <TextInput source="configs.phoneNumberText.marginBottom" label="Phone Number Margin Bottom"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('phoneNumberText', 'marginBottom', e.target.value)}/>
                            <TextInput source="configs.phoneNumberText.paddingTop" label="Phone Number Padding top"
                                       style={{width: '50%'}}
                                       onChange={(e) => handleOnChange('phoneNumberText', 'paddingTop', e.target.value)}/>
                        </TabbedForm.Tab>

                    </TabbedForm>
            </Box>
            <Box gridColumn="span 4" style={{padding: "20px"}}>
                <QrPreview vcardData={previewData}/>
            </Box>
        </Box>

</Create>

)
    ;
}

