import {
    AutocompleteInput, Button,
    Create,
    DateField, ReferenceField,
    ReferenceInput, required, SelectInput,
    SimpleForm, SimpleShowLayout,
    TextField,
    TextInput, useCreateContext,
    useDataProvider, useGetList, useGetOne, useRecordContext,
    useRedirect,
    email,
    
} from "react-admin";
import {Box, Dialog, Divider, FilledInput, FormLabel, Input, OutlinedInput, Tab, Tabs} from "@mui/material";
import * as React from "react";
import * as PropTypes from "prop-types";
import {useEffect, useState} from "react";
import PhoneInput from 'react-phone-number-input/input'
import 'react-phone-number-input/style.css'
import {MuiTelInput} from 'mui-tel-input'
import countries from '../countries/data.json';
import rawData from "../locations/data.json";
import md5 from "md5";
import {PageTemplateSelect} from "../qrpagetemplates/PageTemplateSelect";
import {QrPreview} from "../qrpreview/QrPreview";
import {ImageConfigField} from "../qrdesigns/ImageConfigField";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import Autocomplete from "react-google-autocomplete";
import {AddressAutofill} from '@mapbox/search-js-react';

const validateEmail = [required(),email()];

const countryList = countries.map((country) => {
    return {...country, id: country.Country, name: country.Country}
});

function SectionTitle(props) {
    return null;
}

SectionTitle.propTypes = {label: PropTypes.string};
export const BusinessCardCreate = () => {
    const [country, setCountry] = React.useState(null);
    const [location, setLocation] = React.useState(null);
    const dataProvider = useDataProvider();
    const {data, isLoading} = useGetList('qr/pagetemplates');
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [configs, setConfigs] = useState({});
    const [workPhoneNumber, setWorkPhoneNumber] = useState(null);
    const [mobilePhoneNumber, setMobilePhoneNumber] = useState(null);

    const [addressInputType, setAddressInputType] = useState("1");

    const [choices, setChoices] = useState([]);
    const [vcardData, setVcardData] = useState({
        data: {
            firstName: "",
            lastName: "",
            jobTitle: "",
            website: "",
            emails: [{email: "", emailType: "Primary"}],
            phoneNumbers: [{number: "", phoneType: "Work"}, {number: "", phoneType: "Cell"}],
            addresses: [{addressType: "", organization: "", street: "", city: "", state: "", zipcode: "", country: ""}],
        },
    });

    const [tabValue, setTabValue] = React.useState("1"); // 1 = auto , 2 = manual //addressType

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        setAddressInputType(newValue);
    };

    const locationToQuery = (country) => {
        return {country: country}
    }


    useEffect(() => {
        if (!isLoading) {
            const newArray = data?.map(item => ({id: item.id, name: item.name}));
            setChoices(newArray);
        }
    }, [isLoading])

    const [value, setValue] = useState({})

    const transform = async (data) => {

        let locData = {
            Company: "Axalta Coating Systems"
        };
        data.data.addressInputType = addressInputType || "1";

        switch (addressInputType) {
            case "1":
                try {
                    const loc = await dataProvider.getOne('locations', {id: location});
                    locData = loc.data;
                    data.data.organization = locData.Company;
                } catch (e) {
                    data.data.organization = locData.Company;
                }
                break;
            case "2":
                data.data.organization = "";
                break;
            case "4":
                locData = {
                    ...locData,
                    // Street: data.data.street,
                    // City: data.data.city,
                    // State: data.data.state,
                    // Zipcode: data.data.postcode,
                    // Country: data.data.country
                    Company: data.data.addresses[0].organization,
                    Street: data.data.addresses[0].street,
                    City: data.data.addresses[0].city,
                    "State/Province": data.data.addresses[0].state,
                    "Postal Code": data.data.addresses[0].zipcode,
                    Country: data.data.addresses[0].country,
                }
                value.data = {};
                value.data.locationId = null;
                value.data.countryId = null;
                value.data.addressInputType = addressInputType;
                break;
        }


        data.data.addresses = [
            {
                addressType: 'Work',
                organization: locData.Company,
                street: locData.Street,
                city: locData.City,
                state: locData['State/Province'],
                zipcode: locData['Postal Code'],
                country: country
            }
        ];
        data.data.phoneNumbers = []

        if(workPhoneNumber) {
            data.data.phoneNumbers.push({number: workPhoneNumber, phoneType: "Work"})
        }

        if(mobilePhoneNumber) {
            data.data.phoneNumbers.push({number: mobilePhoneNumber, phoneType: "Cell"})
        }


        data.data.profilePicture = "https://cdn.scanbuy.com/assets/images/axalta_logo_red.png";
        if (selectedTemplate) {
            data.dataDetail = configs;
            data.dataDetail.pageTemplateId = selectedTemplate;
        }
        return data;
    };


    const handleSelect = (value) => {
        setSelectedTemplate(value);
        const selectedConfig = data.filter((d) => d.id === value);
        setConfigs(selectedConfig[0]?.configs);
        setVcardData((prevData) => ({
            ...prevData,
            data: {
                ...prevData.data,
            },
            dataDetail: selectedConfig[0]?.configs
        }));
    }

    const handleOnChange = async (fieldName, fieldValue) => {
        console.log(fieldName + " = " + fieldValue);

        if (fieldName === "emails") {
            setVcardData((prevData) => ({
                ...prevData,
                data: {
                    ...prevData.data,
                    [fieldName]: [{email: fieldValue, emailType: "Primary"}],
                },
            }));

        } else if (fieldName === "workPhoneNumber") {
            setWorkPhoneNumber(fieldValue);
            const workItem = {number: fieldValue, phoneType: "Work"};

            setVcardData((prevData) => {
                let prevPhone = prevData.data.phoneNumbers;
                prevPhone = prevPhone.filter((item) => item.phoneType !== "Work");
                prevPhone.push(workItem);
                return ({
                    ...prevData,
                    data: {
                        ...prevData.data,
                        phoneNumbers: prevPhone,
                    },
                })
            });



        } else if (fieldName === "mobilePhoneNumber") {
            setMobilePhoneNumber(fieldValue);
            setVcardData((prevData) => {
                let prevPhone = prevData.data.phoneNumbers;
                prevPhone = prevPhone.filter((item) => item.phoneType !== "Cell");
                prevPhone.push({number: fieldValue, phoneType: "Cell"});
                return ({
                    ...prevData,
                    data: {
                        ...prevData.data,
                        phoneNumbers: prevPhone,
                    },
                })
            });

        } else if (fieldName === "addresses") {
            let locData;

            const loc = await dataProvider.getOne('locations', {id: fieldValue});
            locData = loc.data;

            setVcardData((prevData) => ({
                ...prevData,
                data: {
                    ...prevData.data,
                    organization: locData?.Company,
                    [fieldName]: [{
                        addressType: "Work",
                        organization: locData?.Company,
                        street: locData?.Street,
                        city: locData?.City,
                        state: locData['State/Province'],
                        zipcode: locData['Postal Code'],
                        country: country
                    }],
                },
            }));
        } else if (fieldName === "street") {

            setVcardData((prevData) => ({
                ...prevData,
                data: {
                    ...prevData.data,
                    addresses: [{
                        addressType: "Work",
                        organization: prevData.data.organization || "",
                        street: fieldValue,
                        city: prevData.data?.addresses[0]?.city || "",
                        state: prevData.data?.addresses[0]?.state || "",
                        zipcode: prevData.data?.addresses[0]?.zipcode || "",
                        country: country
                    }],
                },
            }));

        } else if (fieldName === "city") {
            setVcardData((prevData) => ({
                ...prevData,
                data: {
                    ...prevData.data,
                    addresses: [{
                        addressType: "Work",
                        organization: prevData.data.organization || "",
                        street: prevData.data?.addresses[0].street || "",
                        city: fieldValue,
                        state: prevData.data?.addresses[0].state || "",
                        zipcode: prevData.data?.addresses[0].zipcode || "",
                        country: country
                    }],
                },
            }));


        } else if (fieldName === "state") {
            setVcardData((prevData) => ({
                ...prevData,
                data: {
                    ...prevData.data,
                    addresses: [{
                        addressType: "Work",
                        organization: prevData.data.organization || "",
                        street: prevData.data?.addresses[0].street || "",
                        city: prevData.data?.addresses[0].city || "",
                        state: fieldValue,
                        zipcode: prevData.data?.addresses[0].zipcode || "",
                        country: country
                    }],
                },
            }));

        } else if (fieldName === "zipcode") {
            setVcardData((prevData) => ({
                ...prevData,
                data: {
                    ...prevData.data,
                    addresses: [{
                        addressType: "Work",
                        organization: prevData.data.organization || "",
                        street: prevData.data?.addresses[0].street || "",
                        city: prevData.data?.addresses[0].city || "",
                        state: prevData.data?.addresses[0].state || "",
                        zipcode: fieldValue,
                        country: country
                    }],
                },
            }));


        } else if (fieldName === "country") {
            setCountry(fieldValue);
            setVcardData((prevData) => ({
                ...prevData,
                data: {
                    ...prevData.data,
                    addresses: [{
                        addressType: "Work",
                        organization: prevData.data.organization || "",
                        street: prevData.data?.addresses[0].street || "",
                        city: prevData.data?.addresses[0].city || "",
                        state: prevData.data?.addresses[0].state || "",
                        zipcode: prevData.data?.addresses[0].zipcode || "",
                        country: fieldValue
                    }],
                },
            }));


        } else {
            setVcardData((prevData) => ({
                ...prevData,
                data: {
                    ...prevData.data,
                    [fieldName]: fieldValue,
                },
            }));
        }
    };


    return (
        <>
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <Box gridColumn="span 8">
                    <Create
                        resource="businesscards"
                        // mutationOptions={{ onSuccess }}
                        // sx={{ width: 500, '& .RaCreate-main': { mt: 0 } }}
                        transform={transform}
                    >
                        <SimpleForm record={{type: "vcard", status: "PUBLISHED"}}>
                            <SectionTitle label="resources.businesscards.fieldGroups.contactInfo"/>
                            <Box display={{xs: 'block', sm: 'flex', width: '50%'}}>
                                <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                    <TextInput source="data.firstName" isRequired fullWidth label="First Name"
                                               onChange={(e) => handleOnChange("firstName", e.target.value)}/>
                                </Box>
                                <Box flex={1} ml={{xs: 0, sm: '0.5em'}}>
                                    <TextInput source="data.lastName" isRequired fullWidth label="Last Name"
                                               onChange={(e) => handleOnChange("lastName", e.target.value)}/>
                                </Box>
                            </Box>

                            <TextInput source="data.emails[0].email" label={"Email Address"} style={{width: '50%'}}
                                       onChange={(e) => handleOnChange("emails", e.target.value)}
                                        validate={validateEmail}
                            />
                            <TextInput source="data.jobTitle" label={"Job Title"} style={{width: '50%'}}
                                       onChange={(e) => handleOnChange("jobTitle", e.target.value)}/>
                            <TextInput source="data.jobRole" label={"Job Role"} style={{width: '50%'}}
                                       onChange={(e) => handleOnChange("jobRole", e.target.value)}/>
                            <TextInput source="data.website" label={"Website"} style={{width: '50%'}}
                                       onChange={(e) => handleOnChange("website", e.target.value)}/>

                            <MuiTelInput
                                name={"workPhoneNumber"}
                                defaultCountry={"US"}
                                label={"Work Number"}
                                style={{width: '50%'}}
                                value={workPhoneNumber}
                                onChange={(value, info) => {
                                    setWorkPhoneNumber(value);
                                    handleOnChange("workPhoneNumber", value)
                                }}/>
                            <MuiTelInput
                                name={"cellPhoneNumber"}
                                defaultCountry={"US"}
                                label={"Mobile Number"}
                                style={{width: '50%'}}
                                value={mobilePhoneNumber}
                                onChange={(value, info) => {
                                    setMobilePhoneNumber(value);
                                    handleOnChange("mobilePhoneNumber", value)
                                }}/>
                            <br/>
                            <FormLabel component="legend">Location Address:</FormLabel>
                            <Box sx={{width: '100%', typography: 'body1'}}>
                                <TabContext value={tabValue} style={{width: '50%'}}>

                                    <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                        <Tab label="Select" value="1"/>
                                        <Tab label="Manual" value="2"/>
                                        {/*<Tab label="Google Map" value="3" />*/}
                                        <Tab label="Search" value="4"/>
                                    </TabList>

                                    <TabPanel value="1">
                                        <ReferenceInput
                                            source="data.countryId"
                                            reference="countries"
                                        >
                                            <SelectInput label="Country" onChange={(event) => {
                                                console.log(event.target.value);
                                                setCountry(event.target.value)
                                            }}
                                                         style={{width: '50%'}}
                                            />
                                        </ReferenceInput>

                                        <ReferenceInput
                                            source="data.locationId"
                                            reference="locations"
                                            enableGetChoices={() => {
                                                return country > ""
                                            }}

                                            filter={locationToQuery(country)}
                                            queryOptions={{}}
                                        >
                                            <AutocompleteInput label="Location" isRequired style={{width: '50%'}}
                                                               onChange={(event) => {
                                                                   setLocation(event)
                                                                   handleOnChange("addresses", event)
                                                               }}
                                                               filterToQuery={searchText => ({
                                                                   country: country,
                                                                   name: searchText
                                                               })}
                                            />
                                        </ReferenceInput>
                                    </TabPanel>
                                    <TabPanel value="2">
                                        {/*<TextInput source="data.addresses[0].organization" label={"Company"} style={{width: '50%'}} />*/}
                                        <TextInput source="data.addresses[0].street" label={"Street"}
                                                   style={{width: '50%'}}
                                                   onChange={(e) => handleOnChange("street", e.target.value)}/><br/>
                                        <TextInput source="data.addresses[0].city" label={"City"} style={{width: '50%'}}
                                                   onChange={(e) => handleOnChange("city", e.target.value)}/><br/>
                                        <TextInput source="data.addresses[0].state" label={"State"}
                                                   style={{width: '50%'}}
                                                   onChange={(e) => handleOnChange("state", e.target.value)}/><br/>
                                        <TextInput source="data.addresses[0].zipcode" label={"Zipcode"}
                                                   style={{width: '50%'}}
                                                   onChange={(e) => handleOnChange("zipcode", e.target.value)}/><br/>
                                        <TextInput source="data.addresses[0].country" label={"Country"}
                                                   style={{width: '50%'}}
                                                   onChange={(e) => handleOnChange("country", e.target.value)}/><br/>

                                    </TabPanel>
                                    <TabPanel value="3">
                                        <Autocomplete
                                            apiKey="AIzaSyCGz3R8u_vDjun_i0_IA8A5G76HtOLagAI"
                                            style={{width: "90%"}}
                                            onPlaceSelected={(place) => {
                                                console.log(place);
                                            }}
                                            options={{
                                                fields: ["ALL"],
                                                // componentRestrictions: { country: "us" },
                                            }}
                                            // defaultValue={["places"]}
                                        />;
                                    </TabPanel>
                                    <TabPanel value="4">
                                        <Box
                                            component="form"
                                            // sx={{
                                            //     '& .MuiTextField-root': {m: 1, width: '25ch'},
                                            // }}
                                            noValidate
                                            autoComplete="off"
                                            style={{boxSizing: "inherit"}}
                                        >
                                            <AddressAutofill
                                                accessToken="pk.eyJ1IjoiZ2xlbi1zYiIsImEiOiJjbG9seTV2ZnUwaHNzMmtyeGp4dHJqMGhmIn0.kfUWpwteqF1rkmHg1w1IDA"
                                                theme={{
                                                cssText: `
                                                    *, *:before, *:after {
                                                        box-sizing: inherit !important;
                                                      }
                                                      .mapbox-address-autofill {
                                                        box-sizing: content-box;
                                                      }
                                                    `
                                                }}
                                            >
                                                <TextInput
                                                    name="data.addresses[0].street" placeholder="Address" type="text"
                                                    autoComplete="address-line1"
                                                    style={{width: '50%', color: 'black'}}
                                                    label="Address"
                                                    source="data.addresses[0].street"
                                                    onChange={(e) => handleOnChange("street", "" + e.target.value)}
                                                />
                                            </AddressAutofill>
                                            <br/>
                                            <TextInput
                                                name="data.addresses[0].street2"
                                                placeholder="Address Line 2" type="text"
                                                label="Address Line 2"
                                                autoComplete="address-line2"
                                                source="data.addresses[0].street2"
                                                style={{width: '50%'}}
                                                onChange={(e) => handleOnChange("street2", e.target.value)}
                                            />
                                            <br/>
                                            <TextInput
                                                name="data.addresses[0].city"
                                                placeholder="City" type="text"
                                                label={"City"}
                                                autoComplete="address-level2"
                                                source="data.addresses[0].city"
                                                style={{width: '50%'}}
                                                onChange={(e) => handleOnChange("city", e.target.value)}
                                            />
                                            <br/>
                                            <TextInput
                                                name="data.addresses[0].state" placeholder="State" type="text"
                                                autoComplete="address-level1"
                                                source="data.addresses[0].state"
                                                style={{width: '50%'}}
                                                label={"State"}
                                                onChange={(e) => handleOnChange("state", e.target.value)}
                                            />
                                            <br/>
                                            <TextInput
                                                name="data.addresses[0].zipcode" placeholder="Postcode" type="text"
                                                autoComplete="postal-code"
                                                label={"Postal Code"}
                                                source="data.addresses[0].zipcode"
                                                style={{width: '50%'}}
                                                onChange={(e) => handleOnChange("zipcode", e.target.value)}
                                            />
                                            <br/>
                                            <TextInput
                                                name="data.addresses[0].country" placeholder="Country" type="text"
                                                label={"Country"}
                                                autoComplete="country-name"
                                                source="data.addresses[0].country"
                                                style={{width: '50%'}}
                                                onChange={(e) => handleOnChange("country", e.target.value)}
                                            />
                                            <br/>

                                        </Box>
                                    </TabPanel>
                                </TabContext>


                            </Box>


                            <SelectInput source="qrpagetemplates" label="Page Template" choices={choices}
                                         onChange={(event) => {
                                             console.log(event.target.value);
                                             handleSelect(event.target.value);
                                         }}
                                         style={{width: '50%'}}
                                         value={selectedTemplate}
                            />


                        </SimpleForm>
                    </Create>
                </Box>
                <Box gridColumn="span 4" style={{padding: '20px'}}>
                    Preview:
                    <QrPreview vcardData={vcardData}/>
                </Box>
            </Box>
        </>
    );
}

