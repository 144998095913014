
import apple from "@mui/icons-material/Apple";
import android from "@mui/icons-material/Android";
import link from "@mui/icons-material/Link";



export const IOSIcon = apple
export const AndroidIcon= android
export const LinkIcon= link
