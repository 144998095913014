
import * as React from 'react';
import Vcard from "../vcard/VCard";
import {VCardViewState} from "../vcard/VCardViewState";

export const QrPreview = ({vcardData, configs}) => {
    const experienceData = vcardData?.data;
    let styles = vcardData?.dataDetail;
    const vCardViewState = VCardViewState.create(experienceData);
    vCardViewState.setStyles(styles || {});
    return (
        <>
            <Vcard viewState={vCardViewState}/>
        </>
    );

}