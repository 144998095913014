import {
    Button,
    DateField,
    Show,
    SimpleShowLayout, TabbedShowLayout,
    TextField,
    UrlField,
    useRecordContext,
    useRedirect
} from "react-admin";
import {AndroidIcon, IOSIcon, LinkIcon} from "./utils";
import {ShortUrl} from "../sitelinks/Sitelinks";
import * as React from "react";
import {Box, Divider} from "@mui/material";
import {ImageConfigField} from "../qrdesigns/ImageConfigField";


export const ApplinkShow = () => {

    const redirect = useRedirect();
    const record = useRecordContext();
    console.log(record)
    const handleClose = () => {
        redirect('list', 'applinks');
    };

    return (
        <Show title={"App"}>
            <TabbedShowLayout>
                <TabbedShowLayout.Tab label="Details">

                    <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                        <Box gridColumn="span 8">
                            <SimpleShowLayout>
                                <TextField source="name" style={{width: '50%'}}/>
                                <IOSIcon/><UrlField source="data.appStore" label="iOS Url" style={{width: '50%'}}/>
                                <AndroidIcon/><UrlField source="data.playStore" label="Android Url"
                                                        style={{width: '50%'}}/>
                                <LinkIcon/><UrlField source="data.fallback" label="Fallback Url"
                                                     style={{width: '50%'}}/>
                                <Divider/>
                                <ShortUrl source="shortUrl" label="Short Url"/>
                                <TextField source="status"/>
                                <DateField source="updatedAt"/>
                                <DateField source="createdAt"/>
                            </SimpleShowLayout>
                            <Divider/>
                            <Button variant="contained" onClick={handleClose} label={"Back"}/>
                        </Box>
                        <Box gridColumn="span 4">
                            <ImageConfigField/>
                        </Box>
                    </Box>
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label="Analytics" path="analytics">
                    <h1>Coming Soon..</h1>
                    <div id={"superset-analytics"}></div>
                </TabbedShowLayout.Tab>
            </TabbedShowLayout>
        </Show>
    )
}
