import {fetchUtils, NumberInput, TextInput, useDataProvider, useGetList, useRecordContext} from "react-admin";
import * as React from "react";
import {Divider, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import TextField from '@mui/material/TextField';
import {useEffect, useState} from "react";
import {QrPreview} from "../qrpreview/QrPreview";


export const PageTemplateSelect = () => {
    const record = useRecordContext();
    const { data , isLoading } = useGetList('qr/pagetemplates');
    const [selectedTemplate, setSelectedTemplate] = React.useState("");
    const [vcardData, setVcardData] = React.useState(record);
    const dataProvider = useDataProvider();

    useEffect(()=> {
        dataProvider.getOne("businesscards", { id: record.id })
            .then((res) => {
                if (res?.data?.dataDetail) {
                    setSelectedTemplate(res.data.dataDetail?.pageTemplateId);
                }
            });
    }, []);

     const handleChange = async (value) => {
        setSelectedTemplate(value);
        const selectedData = data.filter((d) => d.id === value);
        record.dataDetail = selectedData[0]?.configs;
        setVcardData(record);
    }
    return (
        <div>
            <FormControl fullWidth>
                <InputLabel id="card-template-select-label">Template</InputLabel>
                <Select
                    labelId="card-template-select-labe"
                    id="card-template-config-select"
                    value={selectedTemplate}
                    label="Template"
                    onChange={(e)=>{
                        handleChange(e.target.value);
                    }}
                    displayEmpty
                >
                    {!isLoading && data && data.map((item) => (
                        <MenuItem value={item.uuid} key={item.uuid}>{item.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Divider/>
            <QrPreview vcardData = {vcardData}/>
            <br/>
        </div>
    )

}