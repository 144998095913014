import * as React from 'react';
import {CheckForApplicationUpdate, Layout, LayoutProps} from 'react-admin';

import AppBar from './AppBar';

import Menu from './Menu';

export default ({children, ...props}) => (
    <Layout {...props} appBar={AppBar} menu={Menu}>
        {children}
        <CheckForApplicationUpdate/>
    </Layout>
);