import { Auth0Client } from '@auth0/auth0-spa-js';
import {Auth0AuthProvider} from "ra-auth-auth0";
import config from "./configs";
import jwt from 'jwt-decode' // import dependency


const auth0 = new Auth0Client({
    domain: config.AUTH0_DOMAIN, // "scanbuyportal-local.auth0.com",
    clientId: config.AUTH0_CLIENT_ID, //"uRYslHe1uNSpvD8j4lEesimST0qxkMTQ",
    cacheLocation: 'localstorage',
    authorizationParams: {
        //audience: "",
    },
});

export const authProvider = Auth0AuthProvider(auth0, {
    loginRedirectUri: config.PORTAL_URL, //"http://localhost:3000/",
    logoutRedirectUri: config.PORTAL_URL, //"http://localhost:3000/",
});





export const login = async () => {
  await auth0.loginWithRedirect();
};

export const logout = () => {
  auth0.logout({ returnTo: window.location.origin });
};

export const getToken = async () => {
  const token = await auth0.getTokenSilently();
  return token;
};
export const getIdToken = async () => {
    const token = await auth0.getIdTokenClaims();
    return token;
}

export const handleRedirectCallback = async () => {
  await auth0.handleRedirectCallback();
};

export const isAuthenticated = async () => {
  const isAuthenticated = await auth0.isAuthenticated();
  return isAuthenticated;
};


export const getPermissions = async (params) => {
    const token = await getToken();
    const decodedToken = jwt(token);
    // TODO: Check the  `http://scanbuy.com/dashboard/groups` contains the config.TENANT_UUID
    console.log(decodedToken);
    return []
}

export const checkAuth = async () => {
    const isAuthenticatedResult = await isAuthenticated();
    return isAuthenticatedResult;
}
export const checkError = async (error) => {
    return false;
}

