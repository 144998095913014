import {
    Button,
    DateField,
    Show,
    SimpleShowLayout,
    TabbedShowLayout,
    TextField, useDataProvider,
    useRecordContext,
    useRedirect
} from "react-admin";
import {Box, Divider} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {QrPreview} from "../qrpreview/QrPreview";

export const PageTemplateShow = ({open, id}) => {
    const redirect = useRedirect();
    const record = useRecordContext();
    const params = useParams();
    const dataProvider = useDataProvider();
    console.log(record)
    const handleClose = () => {
        redirect('list', 'qrpagetemplates');
    };

    const [previewData, setPreviewData] = useState({
        data: {
            firstName: "John",
            lastName: "John",
            emails: [{email: "John@gmail.com", emailType: "Primary"}],
            phoneNumbers: [{number: "87690867343", phoneType: "Work"}],
            addresses: [{
                addressType: "Work",
                organization: "Google",
                street: "431 Clevand Ave",
                city: "test",
                state: "California",
                zipcode: "10082",
                country: "US"
            }],
            jobTitle: "Software Developer",
            website: "www.google.com",
            profilePicture: "https://cdn.scanbuy.com/assets/images/axalta_logo_red.png",
        },
        dataDetail: {
            "primaryVCardContainer": {
                "fontSize": "",
                "color": "",
                "fontFamily": "Arial",
                "fontWeight": "bold",
                "fontStyle": "normal",
                "textAlign": "left",
                "border": "",
                "borderRadius": "",
                "margin": "0px",
                "backgroundColor": "white"
            },
            "secondaryVCardContainer": {
                "fontSize": "21px",
                "color": "black",
                "fontFamily": "Arial",
                "fontWeight": "normal",
                "fontStyle": "normal",
                "textAlign": "left",
                "borderRadius": "0px",
                "paddingBottom": "15px",
                "margin": "0px",
                "backgroundColor": "white",
                "marginTop": "0px",
                "paddingBlockStart": "0px",
                "border": "0px solid teal"
            },
            "imageVCardContainer": {
                "width": "128px",
                "height": "128px",
                "fit": "cover",
                "marginTop": "15px",
                "textAlign": "left"
            },
            "downloadVCardButtonContainer": {
                "borderRadius": "5px",
                "backgroundColor": "white",
                "paddingTop": "45px",
                "border": "0px solid teal"
            },
            "downloadVCardButtonText": {
                "fontSize": "20px",
                "color": "black",
                "fontWeight": "bold",
                "alignSelf": "center",
                "textTransform": "uppercase",
                "border": "1px solid white",
                "backgroundColor": "lightblue",
                "borderRadius": "10px",
                "textDecoration": "none",
                "padding": "10px"
            },
            "vcardNameText": {
                "fontSize": "30px",
                "color": "black",
                "fontFamily": "Arial",
                "fontWeight": "bold",
                "fontStyle": "normal",
                "textAlign": "left"
            },
            "subtitleText": {
                "fontSize": "20px",
                "color": "black",
                "fontFamily": "Arial",
                "fontWeight": "bold",
                "fontStyle": "normal",
                "textAlign": "left",
                "marginBottom": "0px"
            },
            "phoneNumberText": {
                "fontSize": "20px",
                "color": "black",
                "fontFamily": "Arial",
                "fontStyle": "normal",
                "textAlign": "left",
                "marginBottom": "0px",
                "paddingTop": "25px"
            }
        }
    });

    useEffect(() => {
        dataProvider.getOne("qrpagetemplates", {id: params.id})
            .then((res) => {
                console.log("res", res);
                setPreviewData((prevData) => ({
                    ...prevData,
                    dataDetail: res?.data.configs
                }));
            });
    }, [])


    return (
        <Show title={"Card Templates"}>
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <Box gridColumn="span 8">
                    <SimpleShowLayout>
                        <TextField source="name" label="Name"/>
                        <Divider/>
                        <TextField source="configs.primaryVCardContainer.fontSize" label="Primary Font Size"/>
                        <TextField source="configs.primaryVCardContainer.color" label="Primary Font Color"/>
                        <TextField source="configs.primaryVCardContainer.fontFamily" label="Primary Font Family"/>
                        <TextField source="configs.primaryVCardContainer.fontWeight" label="Primary Font Weight"/>
                        <TextField source="configs.primaryVCardContainer.fontStyle" label="Primary Font Style"/>
                        <TextField source="configs.primaryVCardContainer.textAlign" label="Primary Text Alignment"/>
                        <TextField source="configs.primaryVCardContainer.border" label="Primary Border"/>
                        <TextField source="configs.primaryVCardContainer.borderRadius"
                                   label="Primary Border Radius"/>
                        <TextField source="configs.primaryVCardContainer.margin" label="Primary Margin"/>
                        <TextField source="configs.primaryVCardContainer.backgroundColor"
                                   label="Primary Background Color"/>
                        <Divider/>

                        <TextField source="configs.secondaryVCardContainer.fontSize" label="Secondary Font Size"/>
                        <TextField source="configs.secondaryVCardContainer.color" label="Secondary Font Color"/>
                        <TextField source="configs.secondaryVCardContainer.fontFamily"
                                   label="Secondary Font Family"/>
                        <TextField source="configs.secondaryVCardContainer.fontWeight"
                                   label="Secondary Font Weight"/>
                        <TextField source="configs.secondaryVCardContainer.fontStyle" label="Secondary Font Style"/>
                        <TextField source="configs.secondaryVCardContainer.textAlign"
                                   label="Secondary Text Alignment"/>
                        <TextField source="configs.secondaryVCardContainer.border" label="Secondary Border"/>
                        <TextField source="configs.secondaryVCardContainer.borderRadius"
                                   label="Secondary Border Radius"/>
                        <TextField source="configs.secondaryVCardContainer.paddingBottom"
                                   label="Secondary Padding"/>
                        <TextField source="configs.secondaryVCardContainer.margin" label="Secondary Margin"/>
                        <TextField source="configs.secondaryVCardContainer.backgroundColor"
                                   label="Secondary Background Color"/>
                        <TextField source="configs.secondaryVCardContainer.marginTop" label="Secondary Margin Top"/>
                        <TextField source="configs.secondaryVCardContainer.paddingBlockStart"
                                   label="Padding Block Start"/>
                        <TextField source="configs.secondaryVCardContainer.border" label="Border"/>
                        <Divider/>

                        <TextField source="configs.imageVCardContainer.width" label="ImageVCardContainer Width"/>
                        <TextField source="configs.imageVCardContainer.height" label="ImageVCardContainer Height"/>
                        <TextField source="configs.imageVCardContainer.fit" label="ImageVCardContainer Fit"/>
                        <TextField source="configs.imageVCardContainer.textAlign"
                                   label="ImageVCardContainer Text Align"/>
                        <TextField source="configs.imageVCardContainer.marginTop"
                                   label="ImageVCardContainer Margin Top"/>
                        <Divider/>

                        <TextField source="configs.downloadVCardButtonContainer.borderRadius"
                                   label="Button Container Border Radius"/>
                        <TextField source="configs.downloadVCardButtonContainer.backgroundColor"
                                   label="Button Container Background Color"/>
                        <TextField source="configs.downloadVCardButtonContainer.paddingTop"
                                   label="Button Container Padding Top"/>
                        <TextField source="configs.downloadVCardButtonContainer.border"
                                   label="Button Container Border"/>
                        <Divider/>

                        <TextField source="configs.downloadVCardButtonText.fontSize" label="Button Text Font Size"/>
                        <TextField source="configs.downloadVCardButtonText.color" label="Button Text Font Color"/>
                        <TextField source="configs.downloadVCardButtonText.fontWeight"
                                   label="Button Text Font Weight"/>
                        <TextField source="configs.downloadVCardButtonText.alignSelf"
                                   label="Button Text Align Self"/>
                        <TextField source="configs.downloadVCardButtonText.textTransform"
                                   label="Button Text Text Transform"/>
                        <TextField source="configs.downloadVCardButtonText.border" label="Button Text Border"/>
                        <TextField source="configs.downloadVCardButtonText.backgroundColor"
                                   label="Button Text Background Color"/>
                        <TextField source="configs.downloadVCardButtonText.borderRadius"
                                   label="Button Text Border Radius"/>
                        <TextField source="configs.downloadVCardButtonText.textDecoration"
                                   label="Button Text Text Decoration"/>
                        <TextField source="configs.downloadVCardButtonText.padding" label="Button Text Padding"/>
                        <Divider/>

                        <TextField source="configs.vcardNameText.fontSize" label="vCard Name Font Size"/>
                        <TextField source="configs.vcardNameText.color" label="vCard Name Font Color"/>
                        <TextField source="configs.vcardNameText.fontFamily" label="vCard Name Font Family"/>
                        <TextField source="configs.vcardNameText.fontWeight" label="vCard Name Font Weight"/>
                        <TextField source="configs.vcardNameText.fontStyle" label="vCard Name Font Style"/>
                        <TextField source="configs.vcardNameText.textAlign" label="vCard Name Text Align"/>
                        <Divider/>


                        <TextField source="configs.subtitleText.fontSize" label="Subtitle Font Size"/>
                        <TextField source="configs.subtitleText.color" label="Subtitle Font Color"/>
                        <TextField source="configs.subtitleText.fontFamily" label="Subtitle Font Family"/>
                        <TextField source="configs.subtitleText.fontWeight" label="Subtitle Font Weight"/>
                        <TextField source="configs.subtitleText.fontStyle" label="Subtitle Font Style"/>
                        <TextField source="configs.subtitleText.textAlign" label="Subtitle Text Align"/>
                        <TextField source="configs.subtitleText.margin" label="Subtitle Text Margin"/>
                        <Divider/>

                        <TextField source="configs.phoneNumberText.fontSize" label="Phone Number Font Size"/>
                        <TextField source="configs.phoneNumberText.color" label="Phone Number Font Color"/>
                        <TextField source="configs.phoneNumberText.fontFamily" label="Phone Number Font Family"/>
                        <TextField source="configs.phoneNumberText.fontStyle" label="Phone Number Font Style"/>
                        <TextField source="configs.phoneNumberText.textAlign" label="Phone Number Text align"/>
                        <TextField source="configs.phoneNumberText.marginBottom"
                                   label="Phone Number Margin Bottom"/>
                        <TextField source="configs.phoneNumberText.paddingTop" label="Phone Number Padding top"/>
                        <Divider/>
                        <DateField source="createdAt" label="Created"/>
                        <DateField source="updatedAt" label="Updated"/>
                    </SimpleShowLayout>
                    <Divider/>
                    <Button variant="contained" onClick={handleClose} label={"Back"}/>
                </Box>
                <Box gridColumn="span 4">
                    <QrPreview vcardData={previewData}/>
                </Box>
            </Box>
        </Show>
    )
}