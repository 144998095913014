import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faChartPie, faHome} from "@fortawesome/free-solid-svg-icons"

import {
    useTranslate,
    DashboardMenuItem,
    MenuItemLink,
    MenuProps,
    useSidebarState,
} from 'react-admin';

import businesscards from '../businesscards';
import applinks from '../applinks';
import sitelinks from "../sitelinks";
import locations from "../locations";

const Menu = ({ dense = false }) => {

    const translate = useTranslate();
    const [open] = useSidebarState();

    return (
        <Box
            sx={{
                width: open ? 200 : 50,
                marginTop: 1,
                marginBottom: 1,
                transition: theme =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            <DashboardMenuItem
                leftIcon=<FontAwesomeIcon
                className="fa-lg"
                primaryText={translate(`resources.dashboard.name`, {
                    smart_count: 2,
                    _: "Dashboard"
                })}
                style={{size:"30px",color:"#2979a9"}}
                icon={faHome} />
            />
            <MenuItemLink
                to="/businesscards"
                state={{ _scrollToTop: true }}
                primaryText={translate(`resources.businesscard.name`, {
                    smart_count: 2,
                    _: "vCard"
                })}
                leftIcon={businesscards.icon}
            dense={dense}
            />
            <MenuItemLink
                to="/applinks"
                state={{ _scrollToTop: true }}
                primaryText={translate(`resources.applink.name`, {
                    smart_count: 2,
                    _: "App"
                })}
                leftIcon={applinks.icon}
            dense={dense}
            />
            <MenuItemLink
                to="/sitelinks"
                state={{ _scrollToTop: true }}
                primaryText={translate(`resources.sitelink.name`, {
                    smart_count: 2,
                    _: "URL"
                })}
                leftIcon={sitelinks.icon}
            dense={dense}
            />

            <MenuItemLink
                to="/locations"
                state={{ _scrollToTop: true }}
                primaryText={translate(`resources.location.name`, {
                    smart_count: 2,
                    _: "Locations"
                })}
                leftIcon={locations.icon}
                dense={dense}
            />

        </Box>
    );
};

export default Menu;
