import {PageTemplatesList} from "./PageTemplatesList";
import StyleIcon from '@mui/icons-material/Style';
import {PageTemplateShow} from "./PageTemplates";
import {PageTemplateCreate} from "./PageTemplatesCreate";
import {PageTemplateEdit} from "./PageTemplateEdit";


export default {
    list: PageTemplatesList,
    show: PageTemplateShow,
    create: PageTemplateCreate,
    edit: PageTemplateEdit,
    icon: StyleIcon
}