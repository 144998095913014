
import {
    BooleanField,
    Datagrid,
    DateField,
    List,
    ReferenceField,
    Show,
    SimpleShowLayout,
    TextField,
    useRecordContext
} from 'react-admin';
import * as React from "react";

const QrPreview = ({ source }) => {
    const record = useRecordContext();
    if (!record) return null;
    const url = record[source];
    return <img src={url} alt="QR Preview"/>;
};

export const QrDesignList = () => (
    <List>
        <Datagrid rowClick="show">
            <TextField source="name" />
            <QrPreview source="qrPreview" />
            <BooleanField source="is_default" />
            {/*<TextField source="data" />*/}
            {/*<TextField source="id" />*/}
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
        </Datagrid>
    </List>
);


export const QrDesignShow = () => (
    <Show>
        <SimpleShowLayout>
            {/*<TextField source="uuid" />*/}
            <TextField source="name" />
            <BooleanField source="is_default" label={"Default"}/>
            <TextField source="_audit.createdBy" label={"Created By"}/>
            <QrPreview source="qrPreview" />
            {/*<TextField source="data" />*/}
            {/*Preview*/}
        </SimpleShowLayout>
    </Show>
);