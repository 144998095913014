import { DateField, Show, SimpleShowLayout, TextField } from 'react-admin';
import {Divider} from "@mui/material";

//TODO: Add ReactLeaflet Map to show location on map, or OpenStreetMap. on the rigth side of the ui

export const LocationShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source="Country" />
            <TextField source="Location" />
            <Divider/>
            <TextField source="Company" />
            <TextField source="Street" />
            <TextField source="City" />
            <TextField source="State/Province" />
            <TextField source="Postal Code" />
            {/*<TextField source="id" />*/}
        </SimpleShowLayout>
    </Show>
);




