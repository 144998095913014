import {
    AutocompleteInput,
    Button,
    EditBase, ReferenceInput, SelectInput,
    SimpleForm,
    TextInput, useDataProvider, useGetList, useRecordContext
} from "react-admin";
import * as React from "react";
import {useEffect, useState} from "react";
import {useParams} from 'react-router-dom';
import {Box, FilledInput, FormLabel, MenuItem, Tab} from "@mui/material";
import {QrPreview} from "../qrpreview/QrPreview";
import {MuiTelInput} from "mui-tel-input";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import Autocomplete from "react-google-autocomplete";
import {AddressAutofill} from "@mapbox/search-js-react";
import lodash from "lodash";



export const BusinessCardEdit = ({onCancel, ...props}) => {
    const record = useRecordContext(props);
    const [country, setCountry] = React.useState(null);
    const [location, setLocation] = React.useState(null);
    const [choices, setChoices] = useState([]);
    const [templateId, setTemplateId] = useState(null); // State to store the templateId
    const [configs, setConfigs] = useState({});
    const dataProvider = useDataProvider();
    const results = useGetList('qr/pagetemplates');
    const {id} = useParams();
    const [vcardData, setVcardData] = useState(null);
    const [workPhoneNumber, setWorkPhoneNumber] = useState(null);
    const [mobilePhoneNumber, setMobilePhoneNumber] = useState(null);

    const [addressInputType, setAddressInputType] = useState("1");
    const [tabValue, setTabValue] = React.useState("1"); // 1 = auto , 2 = manual //addressType
    const {data} = useGetList('locations');



    useEffect(() => {

        if (!results.isLoading) {
            const newArray = results?.data?.map(item => ({id: item.id, name: item.name}));
            setChoices(newArray);
        }
        dataProvider.getOne("businesscards", {id: id})
            .then((res) => {
                if (res?.data?.dataDetail) {
                    setTemplateId(res.data.dataDetail.pageTemplateId);
                    setConfigs(res.data.dataDetail);
                }
                if (res?.data) {

                    setVcardData(res.data);
                    setCountry(res.data.countryId);
                    setTabValue(res.data.data.addressInputType);
                    setAddressInputType(res.data.data.addressInputType);

                    const workPhone = res.data.data.phoneNumbers.filter(phone => phone.phoneType === "Work");
                    if(workPhone.length > 0){
                        setWorkPhoneNumber(workPhone[0].number);
                    }

                    const mobilePhone = res.data.data.phoneNumbers.filter(phone => phone.phoneType === "Cell");
                    if(mobilePhone.length > 0){
                        setMobilePhoneNumber(mobilePhone[0].number);
                    }

                }

            });

    }, [results.isLoading])

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        setAddressInputType(newValue);
    };
    const locationToQuery = (country) => {
        return {country: country}
    }

    const handleSelect = (value) => {
        setTemplateId(value);
        const selectedConfig = results?.data.filter((d) => d.id === value);
        if (selectedConfig.length === 0) return;
        setConfigs(selectedConfig[0].configs);
        const data = Object.assign({}, vcardData);
        data.dataDetail = selectedConfig[0]?.configs;
        setVcardData(data);
    }


    const handleOnChange = async (fieldName, fieldValue) => {
        if (fieldName === "emails") {
            setVcardData((prevData) => ({
                ...prevData,
                data: {
                    ...prevData.data,
                    [fieldName]: [{email: fieldValue, emailType: "Primary"}],
                },
            }));

        } else if (fieldName === "workPhoneNumber") {
            //TODO: Update the Form, and mark it as Dirty, so the 'save' button is enabled
            setWorkPhoneNumber(fieldValue);
            const workItem = {number: fieldValue, phoneType: "Work"};

            setVcardData((prevData) => {
                let prevPhone = prevData?.data?.phoneNumbers || []
                prevPhone = prevPhone.filter((item) => item.phoneType !== "Work");
                prevPhone.push(workItem);
                return ({
                    ...prevData,
                    data: {
                        ...prevData.data,
                        phoneNumbers: prevPhone,
                    },
                })
            });

        } else if (fieldName === "mobilePhoneNumber") {
            setMobilePhoneNumber(fieldValue);
            setVcardData((prevData) => {
                let prevPhone = prevData?.data?.phoneNumbers || [];
                prevPhone = prevPhone.filter((item) => item.phoneType !== "Cell");
                prevPhone.push({number: fieldValue, phoneType: "Cell"});
                return ({
                    ...prevData,
                    data: {
                        ...prevData.data,
                        phoneNumbers: prevPhone,
                    },
                })
            });

        } else if (fieldName === "addresses") {
            let locData;
            const loc = await dataProvider.getOne('locations', {id: fieldValue});

            locData = loc.data;
            setVcardData((prevData) => ({
                ...prevData,
                data: {
                    ...prevData.data,
                    organization: locData?.Company,
                    [fieldName]: [{
                        addressType: "Work",
                        organization: locData?.Company,
                        street: locData?.Street,
                        city: locData?.City,
                        state: locData['State/Province'],
                        zipcode: locData['Postal Code'],
                        country: locData.country
                    }],
                },
            }));
        } else {
            setVcardData((prevData) => ({
                ...prevData,
                data: {
                    ...prevData.data,
                    [fieldName]: fieldValue,
                },
            }));

        }


    };
    const transform = (value) => {
        let location = {
            Company: "Axalta Coating Systems",
        }
        value.data.addressInputType = addressInputType || "1";

        if (data && (lodash.isEmpty(addressInputType) || addressInputType === "1")) {
            location = data.filter((location => location.id === value.data.locationId))[0];
        } else if (addressInputType === "4" || addressInputType === "2") {
            location = {
                Company: value.data.addresses[0].organization,
                Street: value.data.addresses[0].street,
                City: value.data.addresses[0].city,
                "State/Province": value.data.addresses[0].state,
                "Postal Code": value.data.addresses[0].zipcode,
                Country: value.data.addresses[0].country,
                ...location
            }
            value.data.locationId = null;
            value.data.countryId = null;
            value.data.addressInputType = addressInputType;
        }
        value.data.organization = location?.Company || "Axalta Coating Systems";
        value.data.addresses = [{
            addressType: "Work",
            street: location.Street,
            city: location.City,
            state: location["State/Province"],
            zipcode: location["Postal Code"],
            country: location.Country
        }]

        if (templateId) {
            value.dataDetail = configs;
            value.dataDetail.pageTemplateId = templateId;
        }

        let workPhone = workPhoneNumber;
        let mobilePhone = mobilePhoneNumber;
        value.data.phoneNumbers = [];
        if (workPhone) {
            value.data.phoneNumbers.push({number: workPhone, phoneType: "Work"});
        }
        if ( mobilePhone) {
            value.data.phoneNumbers.push({number: mobilePhone, phoneType: "Cell"});
        }



        return value;

    };

    return (
        <>
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <Box gridColumn="span 8">
                    <EditBase {...props} transform={transform} >
                        <SimpleForm warnWhenUnsavedChanges>
                            <TextInput source="data.firstName" label={"First Name"} style={{width: '50%'}}
                                       onChange={(e) => handleOnChange("firstName", e.target.value)}/>
                            <TextInput source="data.lastName" label={"Last Name"} style={{width: '50%'}}
                                       onChange={(e) => handleOnChange("lastName", e.target.value)}/>
                            {/*<TextInput source="data.phoneNumbers[0].number" label={"Phone Number"}*/}
                            {/*           style={{width: '50%'}}*/}
                            {/*           onChange={(e) => handleOnChange("emails", e.target.value)}/>*/}
                            <MuiTelInput
                                defaultCountry={"US"}
                                label={"Mobile Number"}
                                style={{width: '50%'}}
                                value={mobilePhoneNumber}
                                onChange={(value, info) => {
                                    setMobilePhoneNumber(value);
                                    handleOnChange("mobilePhoneNumber", value)
                                }}/>
                            <MuiTelInput
                                defaultCountry={"US"}
                                label={"Work Number"}
                                style={{width: '50%'}}
                                value={workPhoneNumber}
                                onChange={(value, info) => {
                                    setWorkPhoneNumber(value);
                                    handleOnChange("workPhoneNumber", value)
                                }}/>
                            <TextInput source="data.emails[0].email" label={"Email Address"} style={{width: '50%'}}
                                       onChange={(e) => handleOnChange("phoneNumbers", e.target.value)}/>

                            <TextInput source="data.jobTitle" label={"Job Title"} style={{width: '50%'}}
                                       onChange={(e) => handleOnChange("jobTitle", e.target.value)}/>
                            <TextInput source="data.jobRole" label={"Job Role"} style={{width: '50%'}}
                                       onChange={(e) => handleOnChange("jobRole", e.target.value)}/>
                            <TextInput source="data.website" label={"Website"} style={{width: '50%'}}
                                       onChange={(e) => handleOnChange("website", e.target.value)}/>

                            <hr/>


                            <FormLabel component="legend">Location Address:</FormLabel>
                            <Box sx={{width: '100%', typography: 'body1'}}>
                                <TabContext value={tabValue} style={{width: '50%'}}>

                                    <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                        <Tab label="Select" value="1"/>
                                        <Tab label="Manual" value="2"/>
                                        {/*<Tab label="Google Map" value="3" />*/}
                                        <Tab label="Search" value="4"/>
                                    </TabList>

                                    <TabPanel value="1">
                                        <ReferenceInput
                                            source="data.countryId"
                                            reference="countries"
                                        >
                                            <SelectInput label="Country" onChange={(event) => {
                                                setCountry(event.target.value)
                                            }}
                                                         style={{width: '50%'}}
                                            />
                                        </ReferenceInput>

                                        <ReferenceInput
                                            source="data.locationId"
                                            reference="locations"
                                            enableGetChoices={() => {
                                                return country > ""
                                            }}
                                            filter={locationToQuery(country)}
                                            queryOptions={{}}

                                        >
                                            {/*<SelectInput label="Location" onChange={(event) => {*/}
                                            {/*    handleOnChange("addresses", event.target.value)*/}
                                            {/*}}/>*/}
                                            <AutocompleteInput label="Location" isRequired style={{width: '44%'}}
                                                               onChange={(event) => {
                                                                   setLocation(event)
                                                                   handleOnChange("addresses", event)
                                                               }}
                                                               filterToQuery={searchText => ({
                                                                   country: country,
                                                                   name: searchText
                                                               })}
                                            />
                                        </ReferenceInput>

                                    </TabPanel>
                                    <TabPanel value="2">
                                        {/*<TextInput source="data.addresses[0].organization" label={"Company"} style={{width: '50%'}} />*/}
                                        <TextInput source="data.addresses[0].street" label={"Street"}
                                                   style={{width: '50%'}}
                                                   onChange={(e) => handleOnChange("street", e.target.value)}/><br/>
                                        <TextInput source="data.addresses[0].city" label={"City"} style={{width: '50%'}}
                                                   onChange={(e) => handleOnChange("city", e.target.value)}/><br/>
                                        <TextInput source="data.addresses[0].state" label={"State"}
                                                   style={{width: '50%'}}
                                                   onChange={(e) => handleOnChange("state", e.target.value)}/><br/>
                                        <TextInput source="data.addresses[0].zipcode" label={"Zipcode"}
                                                   style={{width: '50%'}}
                                                   onChange={(e) => handleOnChange("zipcode", e.target.value)}/><br/>
                                        <TextInput source="data.addresses[0].country" label={"Country"}
                                                   style={{width: '50%'}}
                                                   onChange={(e) => handleOnChange("country", e.target.value)}/><br/>

                                    </TabPanel>
                                    <TabPanel value="3">
                                        <Autocomplete
                                            apiKey="AIzaSyCGz3R8u_vDjun_i0_IA8A5G76HtOLagAI"
                                            style={{width: "90%"}}
                                            onPlaceSelected={(place) => {
                                                console.log(place);
                                            }}
                                            options={{
                                                fields: ["ALL"],
                                                // componentRestrictions: { country: "us" },
                                            }}
                                            // defaultValue={["places"]}
                                        />;
                                    </TabPanel>
                                    <TabPanel value="4">
                                        <Box
                                            component="form"
                                            // sx={{
                                            //     '& .MuiTextField-root': {m: 1, width: '25ch'},
                                            // }}
                                            noValidate
                                            autoComplete="off"
                                            style={{boxSizing: "inherit"}}
                                        >
                                            <AddressAutofill
                                                accessToken="pk.eyJ1IjoiZ2xlbi1zYiIsImEiOiJjbG9seTV2ZnUwaHNzMmtyeGp4dHJqMGhmIn0.kfUWpwteqF1rkmHg1w1IDA"
                                                theme={{
                                                    cssText: `
                                                    *, *:before, *:after {
                                                        box-sizing: inherit !important;
                                                      }
                                                      .mapbox-address-autofill {
                                                        box-sizing: content-box;
                                                      }
                                                    `
                                                }}
                                            >
                                                <TextInput
                                                    name="data.addresses[0].street" placeholder="Address" type="text"
                                                    autoComplete="address-line1"
                                                    style={{width: '50%'}}
                                                    label="Address"
                                                    source="data.addresses[0].street"
                                                    onChange={(e) => handleOnChange("street", "" + e.target.value)}
                                                />
                                            </AddressAutofill>
                                            <br/>
                                            <TextInput
                                                name="data.addresses[0].street2"
                                                placeholder="Address Line 2" type="text"
                                                label="Address Line 2"
                                                autoComplete="address-line2"
                                                source="data.addresses[0].street2"
                                                style={{width: '50%'}}
                                                onChange={(e) => handleOnChange("street2", e.target.value)}
                                            />
                                            <br/>
                                            <TextInput
                                                name="data.addresses[0].city"
                                                placeholder="City" type="text"
                                                label={"City"}
                                                autoComplete="address-level2"
                                                source="data.addresses[0].city"
                                                style={{width: '50%'}}
                                                onChange={(e) => handleOnChange("city", e.target.value)}
                                            />
                                            <br/>
                                            <TextInput
                                                name="data.addresses[0].state" placeholder="State" type="text"
                                                autoComplete="address-level1"
                                                source="data.addresses[0].state"
                                                style={{width: '50%'}}
                                                label={"State"}
                                                onChange={(e) => handleOnChange("state", e.target.value)}
                                            />
                                            <br/>
                                            <TextInput
                                                name="data.addresses[0].zipcode" placeholder="Postcode" type="text"
                                                autoComplete="postal-code"
                                                label={"Postal Code"}
                                                source="data.addresses[0].zipcode"
                                                style={{width: '50%'}}
                                                onChange={(e) => handleOnChange("zipcode", e.target.value)}
                                            />
                                            <br/>
                                            <TextInput
                                                name="data.addresses[0].country" placeholder="Country" type="text"
                                                label={"Country"}
                                                autoComplete="country-name"
                                                source="data.addresses[0].country"
                                                style={{width: '50%'}}
                                                onChange={(e) => handleOnChange("country", e.target.value)}
                                            />
                                            <br/>
                                        </Box>
                                    </TabPanel>
                                </TabContext>
                            </Box>
                            <SelectInput source="qrpagetemplates" label="Page Template" choices={choices}
                                         onChange={(event) => {
                                             console.log(event.target.value);
                                             handleSelect(event.target.value);
                                         }}
                                         style={{width: '50%'}}
                                         defaultValue={templateId}
                            />
                        </SimpleForm>
                    </EditBase>
                </Box>
                <Box gridColumn="span 4" style={{padding: '20px'}}>
                    Preview:
                    <QrPreview vcardData={vcardData}/>
                </Box>
            </Box>
        </>
    )
};
