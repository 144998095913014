import {EditBase, SimpleForm, TextInput, useUnique} from "react-admin";
import * as React from "react";
import {IOSIcon, AndroidIcon, LinkIcon} from "./utils";

export const ApplinkEdit = ({onCancel, ...props}) => {

    const unique = useUnique();
    const required = (message = 'Required') =>
        value => value ? undefined : message;
    const checkIfUrl = (message = 'Must be a valid url') =>
        value => value.match("^https?:\\/\\/(?:www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b(?:[-a-zA-Z0-9()@:%_\\+.~#?&\\/=]*)$") ? undefined : message;

    const validateName = [required()] //, unique({message: 'Must be a unique name'}) //TODO: fix , for 'update' it will always fail
    const urlValidator = [required(), checkIfUrl()]

    return (
        <EditBase {...props}>
            <SimpleForm warnWhenUnsavedChanges>
                <TextInput source="name" style={{width: '50%'}} validate={validateName}/>
                <IOSIcon/>
                <TextInput source="data.appStore" label="iOS Url" style={{width: '50%'}} validate={urlValidator}/>
                <AndroidIcon/>
                <TextInput source="data.playStore" label="Android Url" style={{width: '50%'}} validate={urlValidator}/>
                <LinkIcon/><
                TextInput source="data.fallback" label="Fallback Url" style={{width: '50%'}} validate={urlValidator}/>
            </SimpleForm>
        </EditBase>
    );
}
