
import polyglotI18nProvider from 'ra-i18n-polyglot';
import en from 'ra-language-english';
import fr from 'ra-language-french';
import de from 'ra-language-german';
import es from 'ra-language-spanish';
import it from 'ra-language-italian';
import ja from 'ra-language-japanese';
import nl from 'ra-language-dutch';
import pt from 'ra-language-portuguese';
import ru from 'ra-language-russian';
import uk from 'ra-language-ukrainian';
import pl from 'ra-language-polish';
import tr from 'ra-language-turkish';
import vi from 'ra-language-vietnamese';
import cs from 'ra-language-czech';
import sv from 'ra-language-swedish';
import ko from 'ra-language-korean';
import da from 'ra-language-danish';
import ro from 'ra-language-romanian';
import hu from 'ra-language-hungarian';
import el from 'ra-language-greek';
import no from 'ra-language-norwegian';
import fi from 'ra-language-finnish';
import hi from 'ra-language-hindi';
// import bn from 'ra-language-bengali';
// import fa from 'ra-language-persian';
import th from 'ra-language-thai';
import sk from 'ra-language-slovak';
import ca from 'ra-language-catalan';
import hr from 'ra-language-croatian';
import he from 'ra-language-hebrew';
import id from 'ra-language-indonesian';
// import sr from 'ra-language-serbian';
import ms from 'ra-language-malay';
// import fil from 'ra-language-filipino';
import bg from 'ra-language-bulgarian';
import et from 'ra-language-estonian';
import lt from 'ra-language-lithuanian';
import lv from 'ra-language-latvian';
// import ar from 'ra-language-arabic'; // need to add RTL support

const translations = {
        en, fr, da, ro, pl, fi,sv,el
        , de, es, it, ja, nl, pt, ru, uk, tr, vi, cs, ko, hu, no, hi, th, sk, ca, hr, he, id, ms, bg, et, lt, lv
};

export const i18nProvider = polyglotI18nProvider(
    locale => translations[locale],
    'en', // default locale
    [
        { locale: 'en', name: 'English' },
        { locale: 'fr', name: 'Français' },
        { locale: 'es', name: 'Español' },
        { locale: 'de', name: 'Deutsch' },
        { locale: 'pt', name: 'Português' },
        { locale: 'zh', name: '中文' },
        { locale: 'ja', name: '日本語' },
        { locale: 'it', name: 'Italiano' },
        { locale: 'ru', name: 'Русский' },
        { locale: 'pl', name: 'Polski' },
        { locale: 'uk', name: 'Українська' },
        { locale: 'nl', name: 'Nederlands' },
        { locale: 'tr', name: 'Türkçe' },
        { locale: 'vi', name: 'Tiếng Việt' },
        { locale: 'cs', name: 'Český' },
        { locale: 'sv', name: 'Svenska' },
        { locale: 'ko', name: '한국어' },
        { locale: 'da', name: 'Dansk' },
        { locale: 'ro', name: 'Română' },
        { locale: 'hu', name: 'Magyar' },
        // { locale: 'el', name: 'Ελληνικά' },
        { locale: 'no', name: 'Norsk' },
        { locale: 'fi', name: 'Suomi' },
        { locale: 'hi', name: 'हिन्दी' },
        { locale: 'bn', name: 'বাংলা' },
        { locale: 'fa', name: 'فارسی' },
        { locale: 'th', name: 'ภาษาไทย' },
        { locale: 'sk', name: 'Slovenský' },
        { locale: 'ca', name: 'Català' },
        { locale: 'hr', name: 'Hrvatski' },
        { locale: 'he', name: 'עברית' },
        { locale: 'id', name: 'Bahasa Indonesia' },
        { locale: 'sr', name: 'Српски' },
        { locale: 'ms', name: 'Bahasa Melayu'},
        { locale: 'fil', name: 'Filipino'},
        { locale: 'bg', name: 'Български'},
        { locale: 'et', name: 'Eesti'},
        { locale: 'lt', name: 'Lietuvių'},
        { locale: 'lv', name: 'Latviešu'},
        { locale: 'ar', name: 'العربية'},
        { locale: 'fi', name:   'Suomi' },
    ],
)
