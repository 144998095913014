import { Card, CardContent, CardHeader } from "@mui/material";
import {SummaryAnalytics} from "../analytics/SummaryAnalytics";

export const Dashboard = () => {

    return (
        <Card style={{height:"100%"}}>
            <CardHeader title="Axalta QR's" />
            <CardContent>Welcome to Scanbuy QR management for Axalta Business Cards, Applinks and Website Links.
                <br/>Create and manage the different types of QR codes below.<br/>
                <br/><b>vCard</b>: QR codes that allow users to view and download your contact information. This QR code can be added to an electronic or physical business card.
                <br/><b>App</b>: QR codes that directs users to an Axalta mobile app on iPhone or Android.
                <br/><b>URL</b>: QR codes that directs users to an Axalta website.
                <br/>
                <h3>Analytics</h3>
                <div style={{width:"100%",height:"900px"}}>
                    <SummaryAnalytics/>
                </div>
            </CardContent>
        </Card>
    );
}
