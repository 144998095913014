import { types } from "mobx-state-tree";

const addNewLine =
    (line="")=>
        line?`${line}\n`:''

const begin = (version="3.0") => {
    let start = addNewLine('BEGIN:VCARD')
    start+=addNewLine (`VERSION:${version}`);
    return start;
};

const end = (vCard) => vCard+'END:VCARD';

class generateVCard{
    constructor(version="4.0"){
        this.vcardString = begin(version);
    }
    addLine (line=""){
        this.vcardString+=addNewLine(line)
        return this;
    }
    toString(){
        return end(this.vcardString);
    }
}

const stringifyObjectForAddress = (obj) => Object.values(obj).filter(Boolean).join(';')


// Mobx View state model for vcard
export const VCardViewState = types.model('VCardViewState',{
    firstName: types.maybeNull(types.optional(types.string,"")),
    lastName: types.maybeNull(types.optional(types.string,"")),
    organization : types.maybeNull(types.optional(types.string,"")),
    jobTitle: types.maybeNull(types.optional(types.string,"")),
    jobRole: types.maybeNull(types.optional(types.string,"")),
    profilePicture: types.maybeNull(types.optional(types.string,"")),
    phoneNumbers: types.optional(types.array(types.model("Phone",{
        phoneType: types.optional(types.string,"Work"), //enum : ['Cell','Mobile','Work','Home','Fax','Other']
        number: types.optional(types.string,""),
    })),[]),
    emails: types.maybeNull(types.optional(types.array(types.model("Email",{
        emailType: types.string, //enum : [Primary, Secondary]
        email: types.optional(types.string,"")
    })),[])),
    website: types.maybeNull(types.optional(types.string,"")),
    birthday: types.maybeNull(types.optional(types.string,"")),
    notes: types.maybeNull(types.optional(types.string,"")),
    revisionNumber : types.maybeNull(types.optional(types.string,"")),
    addresses: types.maybeNull(types.optional(types.array(types.model("Address",{
        addressType: types.string, // enum : [Work, Home]
        street: types.maybeNull(types.optional(types.string,"")),
        city: types.maybeNull(types.optional(types.string,"")),
        state: types.maybeNull(types.optional(types.string,"")),
        zipcode: types.maybeNull(types.optional(types.string,"")),
        country: types.maybeNull(types.optional(types.string,"")),
    })),[])),
    downloadVersion: types.optional(types.string,'3.0'),
    downloadLink: types.optional(types.string,''),
}).volatile(self => ({
    generatedVcard: types.string,
    base64Image: types.string,
    styles: types.object
})).views((self)=>({
    cellNumber() {
        const {number} = self.phoneNumbers?.find(phone => phone.phoneType === "Cell" ) || {};
        return number || "";
    },
    workNumber() {
        const {number} = self.phoneNumbers?.find(phone => phone.phoneType === "Work" )|| {};
        return number || "";
    },
    homeNumber() {
        const {number} = self.phoneNumbers?.find(phone => phone.phoneType === "Home" )|| {};
        return number || "";
    },
    faxNumber() {
        const {number} = self.phoneNumbers?.find(phone => phone.phoneType === "Fax" )|| {};
        return number || "";
    },
    email() {
        const {email} = self.emails?.find(e => e.emailType === "Primary" )|| {};
        return email || "";
    },
    secondaryEmail() {
        const {email} = self.emails?.find(e => e.emailType === "Secondary" ) || {};
        return email || "";
    },
    workAddress() {
        return self.addresses?.find(address => address.addressType === "Work" ) || {}
    },
    homeAddress() {
        return self.addresses?.find(address => address.addressType === "Home" ) || {}
    },
    formatBirthDay(date) {
        const birthday = new Date(date);
        const d = birthday.getDate();
        const m = birthday.getMonth() + 1;
        const y = birthday.getFullYear();
        return '' + y + (m<=9 ? '0' + m : m) + (d <= 9 ? '0' + d : d);
    }
})).actions( (self)=>({
    setStyles(styles){
        self.styles = styles;
    },
    changeDownloadVersion(ver){
        self.downloadVersion = ver;
        self.generateVcf();
    },
    setBase64(data){
        self.base64Image = data;
    },
    updateDownloadLink(link){
        self.downloadLink = link;
    },
    generateVcf(){
        const vCardData = self;
        const {profilePicture} = vCardData;
        if(profilePicture){
            const img = new Image();
            img.setAttribute('crossOrigin', 'anonymous');
            img.onload = () => {
                const canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);
                const dataURL = canvas.toDataURL("image/jpeg");
                self.setBase64( dataURL.split(',')[1]);
                self.buildVcf(vCardData)
            }
            img.src = profilePicture
        } else{
            self.buildVcf(vCardData)
        }
    },
    buildVcf(vCardData) {
        const vCard = new generateVCard(self.downloadVersion);
        //Add vcard attributes

        vCard.addLine(`FN;CHARSET=UTF-8:${vCardData.firstName} ${vCardData.lastName}`)
            .addLine(`N;CHARSET=UTF-8:${vCardData.lastName}; ${vCardData.firstName};;;`)
            .addLine(vCardData.organization&&`ORG;CHARSET=UTF-8:${vCardData.organization}`)
            .addLine(vCardData.jobTitle&&`TITLE;CHARSET=UTF-8:${vCardData.jobTitle}`)
            .addLine(vCardData.jobRole&&`ROLE;CHARSET=UTF-8:${vCardData.jobRole}`)
            .addLine(vCardData.workNumber()&&`TEL;TYPE=WORK,VOICE:${vCardData.workNumber()}`)
            .addLine(vCardData.homeNumber()&&`TEL;TYPE=HOME,VOICE:${vCardData.homeNumber()}`)
            .addLine(vCardData.cellNumber()&&`TEL;TYPE=CELL,VOICE:${vCardData.cellNumber()}`)
            .addLine(vCardData.faxNumber()&&`TEL;TYPE=FAX:${vCardData.faxNumber()}`)
            .addLine(vCardData.email()&&`EMAIL:${vCardData.email()}`)
            .addLine(vCardData.secondaryEmail()&&`EMAIL:${vCardData.secondaryEmail()}`)
            .addLine(vCardData.workAddress().street&&`ADR;type=WORK:;;${vCardData.workAddress().street};${vCardData.workAddress().city};${vCardData.workAddress().zipcode};${vCardData.workAddress().country}`)
            .addLine(vCardData.homeAddress().street&&`ADR;type=HOME;type=pref:;;${vCardData.homeAddress().street};${vCardData.homeAddress().city};${vCardData.homeAddress().zipcode};${vCardData.homeAddress().country}`)
            .addLine(vCardData.website&&`URL:${vCardData.website}`)
            .addLine(vCardData.birthday&&`BDAY:${vCardData.formatBirthDay(vCardData.birthday)}`)
            .addLine(vCardData.notes&&`NOTE:${vCardData.notes}`)
            .addLine(`PHOTO;ENCODING=BASE64;TYPE=JPEG:${self.base64Image}`)
            .addLine()

        self.generatedVcard = vCard.toString();
        console.log(self.generatedVcard);
        self.downloadLink = "data:text/vcard;charset=utf-8," + encodeURIComponent(self.generatedVcard).toString();
    },
    afterCreate(){
        self.generateVcf();
    }

}))
