import {
    BulkDeleteButton,
    Button,
    CreateButton,
    Datagrid,
    DateField, downloadCSV, EditButton, ExportButton, DeleteButton,
    FilterButton,
    List, ShowButton,
    TextField,
    TopToolbar,
    useGetIdentity, useRecordContext
} from "react-admin";
import {matchPath, useLocation, useNavigate} from "react-router-dom";
import {Box, Card, CardActions, CardContent, CardHeader, CardMedia, Drawer} from "@mui/material";
import businessCardFilters from "./businesscardFilters";
import * as React from "react";
import jsonExport from 'jsonexport/dist';
import {useCallback} from "react";
import {BusinessCardEdit} from "./BusinessCardEdit";
import {BusinessCardCreate} from "./BusinessCardCreate";

const VCardUrl = ({ source }) => {
    const record = useRecordContext();
    if (!record) return null;
    const url = record[source].domain + "/" + record[source].uri
    return <a href={url}>{url}</a>;
};
const Phone = ({ source }) => {
    const record = useRecordContext();
    if (!record) return null;
    console.log(record[source]);
    const phoneNumbers = record[source].phoneNumbers;

    if (!phoneNumbers || phoneNumbers.length === 0) {
        return <span> - </span>
    }
    const phone = phoneNumbers[0].number;

    return <span>{phone}</span>;
};


const ListActions = () => (
    <TopToolbar>
        <FilterButton/>
        <ExportButton/>
        <CreateButton/>
        {/* Add your custom actions */}
        {/*<Button*/}
        {/*    onClick={() => { alert('Your custom action'); }}*/}
        {/*    label="Show calendar"*/}
        {/*>*/}
        {/*    <IconEvent/>*/}
        {/*</Button>*/}
    </TopToolbar>
);


export const BusinessCardList = () => {
    const { identity } = useGetIdentity();

    if (identity == null) {
        return
    }

    const exporter = cards => {
        const businessCardsForExport = cards.map(card => {
            const {  owner,_id,uuid,name,
                id,publishOnUpdate,tags,
                locationId,countryId,emailType,
                domain,uri,type,data,createdAt,
                updatedAt,shortUrl,statusDetail,
                dataDetail,status,_audit,_tenantUuid,

                ...cardForExport } = card;

            cardForExport.first_name = card?.data?.firstName ?? "";
            cardForExport.last_name = card?.data?.lastName ?? "";
            cardForExport.phone_number = card?.data?.phoneNumbers[0]?.number ?? "";
            cardForExport.short_url = (card?.shortUrl?.domain && card?.shortUrl?.uri) ?
                card?.shortUrl?.domain + "/" + card?.shortUrl?.uri : "";
            cardForExport.status = card?.status ?? "";
            cardForExport.updated_at = card?.updatedAt ? new Date(card?.updatedAt):"";
            cardForExport.created_at = card?.createdAt ? new Date(card?.createdAt):"";

            return cardForExport;
        });
        jsonExport(businessCardsForExport, {
            headers: ['first_name',"last_name","phone_number","short_url","status","updated_at","created_at"]
        }, (err, csv) => {
            downloadCSV(csv, 'axalta_vcard'); // <-- name download file here
        });
    };
    if (!identity) return null;

    return (
        <Box >
            <List
                exporter={exporter}
                filters={businessCardFilters}
                actions={<ListActions/>}
            >
                <Datagrid
                    rowClick="show"
                    optimized

                    bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" />}
                >
                    <TextField source="data.firstName" label="First Name"/>
                    <TextField source="data.lastName" label="Last Name"/>
                    <Phone source="data" label="Phone"/>
                    <VCardUrl source="shortUrl"/>
                    <TextField source="status"/>
                    <DateField source="updatedAt"/>
                    <DateField source="createdAt"/>
                    <EditButton/>
                    <DeleteButton mutationMode="pessimistic"  />
                </Datagrid>
            </List>

        </Box>

    )
}
