import {LocationList} from "./LocationList";
import {LocationShow} from "./LocationShow";
import locationIcon from "../assets/images/locationIcon.png";
import * as React from "react";


export default {
    list: LocationList,
    show: LocationShow,
    icon: <img style={{width:"22px"}} src={locationIcon} />
}
