import {
    CreateButton,
    Datagrid, DateInput,
    ExportButton,
    FilterButton,
    List,
    SearchInput, SelectInput,
    TextField,
    TopToolbar
} from 'react-admin';
import * as React from "react";

const ListActions = () => (
    <TopToolbar>
        <FilterButton/>
        <ExportButton/>
        <CreateButton/>
        {/*<ExportButton/>*/}
        {/* Add your custom actions */}
        {/*<Button*/}
        {/*    onClick={() => { alert('Your custom action'); }}*/}
        {/*    label="Show calendar"*/}
        {/*>*/}
        {/*    <IconEvent/>*/}
        {/*</Button>*/}
    </TopToolbar>
);



const listFilters = [
    <SearchInput name="search" source="q" alwaysOn />,
    // <SelectInput
    //     name="status"
    //     source="status"
    //     choices={[
    //         { id: 'DRAFT', name: 'DRAFT' },
    //         { id: 'PUBLISHED', name: 'PUBLISHED' }
    //     ]}
    // />,
    // <DateInput name="updatedAt" source="updatedAt" />,
    // <DateInput name="createdAt" source="createdAt" />,
];
export const LocationList = () => (
    <List
        filters={listFilters}
        actions={<ListActions/>}
    >
        <Datagrid rowClick="show">
            <TextField source="Country" />
            <TextField source="Location" />
            <TextField source="Company" />
            <TextField source="Street" />
            <TextField source="City" />
            <TextField source="State/Province" />
            <TextField source="Postal Code" />
            {/*<TextField source="id" />*/}
        </Datagrid>
    </List>
);