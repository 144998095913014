import { embedDashboard } from "@superset-ui/embedded-sdk";
import {apiUrl, httpClient} from "../api";
import {useEffect} from "react";
import config from '../configs';

export const Analytics = ({qrUuid}) => {

    const fetchGuestToken = async () => {
        //TODO: Fetch from the API,  /me/qr/codes/uuid/reports/embeddedReport
        const res = await httpClient(`${apiUrl}/qr/codes/${qrUuid}/reports/embeddedReport`, { method: 'POST'});
        return res.json.token;
    }

    const  init =  async (uuid) => {
        let dashboard = await embedDashboard({
            id: 'e1ef3b6b-aad3-4981-bb36-9acdfe2bfffe',  //e1ef3b6b-aad3-4981-bb36-9acdfe2bfffe
            supersetDomain: config.ANALYTICS_DOMAIN,
            mountPoint: document.getElementById("superset"),
            fetchGuestToken: fetchGuestToken,
            dashboardUiConfig: {
                hideTitle: true,
                hideChartControls: true,
                hideTab: true
            },
            filters: {
                expanded: true,
            }
        })

        dashboard.getScrollSize()?.then((size) => {
            try {
                const { width, height } = size;
                console.log(size)
                console.log(`${width} x ${height}`);

                document.getElementById("superset").firstChild.style.height = "100%";
                document.getElementById("superset").firstChild.style.width = "100%";
                document.getElementById("superset").firstChild.style.overflow = "hidden";
                document.getElementById("superset").firstChild.style.border = "none";
            } catch (e) {
                console.error(e);
            }
        }).catch( (ex) =>{

        })
    }
    useEffect(() => {   init(qrUuid); }, [qrUuid]);

    return (
        <div id="superset" style={{width:"100%",height:"900px"}}/>
    )
}