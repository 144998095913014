import * as React from 'react';

import {
    Button,
    DateField,
    fetchUtils,
    ReferenceField,
    Show,
    SimpleShowLayout,
    TabbedShowLayout,
    TextField,
    useRecordContext, useRedirect,
} from "react-admin";

import {
    Box,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Divider,
} from '@mui/material';

import {ImageConfigField} from "../qrdesigns/ImageConfigField";
import {Analytics} from "../analytics/Analytics";
import {matchPath, useLocation} from "react-router-dom";
import {PageTemplateSelect} from "../qrpagetemplates/PageTemplateSelect";
import {QrPreview} from "../qrpreview/QrPreview";

const VCardUrl = ({ source }) => {
    const record = useRecordContext();
    if (!record) return null;
    const url = record[source].domain + "/" + record[source].uri
    return <a href={url}>{url}</a>;
};
const WorkPhone = ({ source }) => {
    const record = useRecordContext();
    if (!record) return null;
    console.log(record[source]);
    const phoneNumbers = record[source].phoneNumbers;

    if (!phoneNumbers || phoneNumbers.length === 0) {
        return <span> - </span>
    } else if (phoneNumbers.length > 0) {
        //filter out the work phone
        const workPhone = phoneNumbers.filter(phone => phone.phoneType === "Work");
        if(workPhone.length > 0) {
            return <span>{workPhone[0].number}</span>;
        }
    }
    return <span> - </span>
};

const CellPhone = ({ source }) => {
    const record = useRecordContext();
    if (!record) return null;
    console.log(record[source]);
    const phoneNumbers = record[source].phoneNumbers;

    if (!phoneNumbers || phoneNumbers.length === 0) {
        return <span> - </span>
    } else if (phoneNumbers.length > 0) {
        //filter out the work phone
        const workPhone = phoneNumbers.filter(phone => phone.phoneType === "Cell");
        if(workPhone.length > 0) {
            return <span>{workPhone[0].number}</span>;
        }
    }
    return <span> - </span>
};

const PrimaryEmail = ({ source }) => {
    const record = useRecordContext();
    if (!record) return null;
    console.log(record[source]);
    const emailAddresses = record[source].emails;

    if (!emailAddresses || emailAddresses.length === 0) {
        return <span> - </span>
    } else if (emailAddresses.length > 0) {
        //filter out the work phone
        const workEmail = emailAddresses.filter(email => email.emailType === "Primary");
        if(workEmail.length > 0) {
            return <span>{workEmail[0].email}</span>;
        }
    }
    return <span> - </span>
};

const BusinessCardTitle = () => {
    const record = useRecordContext();
    // the record can be empty while loading
    if (!record) return null;

    return <span>vCard: ({record.data.firstName} {record.data.lastName}) </span>;
};

export const BusinessCardShow = ({ open, id }) => {
    const redirect = useRedirect();
    const location = useLocation();
    const handleClose = () => {
        redirect('list', 'businesscards');
    };

    const matchAnalytics = matchPath('/businesscards/:id/show/analytics', location.pathname);

    console.log(matchAnalytics);
    if(matchAnalytics) {
        console.log(matchAnalytics.params.id);
    }
    return (
        <Show title={<BusinessCardTitle/>}>
            <TabbedShowLayout>
                <TabbedShowLayout.Tab label="Details">
                    <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                        <Box gridColumn="span 4" >
                            <SimpleShowLayout>
                                <TextField source="data.firstName" label="First Name"/>
                                <TextField source="data.lastName" label="Last Name"/>
                                <CellPhone source="data" label="Mobile Phone"/>
                                <WorkPhone source="data" label="Work Phone"/>
                                <PrimaryEmail source="data" label="Email Address" />
                                <TextField source="data.jobTitle" label="Job Title"/>
                                <TextField source="data.jobRole" label="Job Role"/>
                                {/*<VCardUrl source="shortUrl"/>*/}
                                <Divider   />
                                <TextField source="status" label="Status"/>
                                <DateField source="updatedAt" label="Updated"/>
                                <ReferenceField
                                    source="data.locationId"
                                    reference="locations"
                                    label="Location"
                                >
                                </ReferenceField>
                            </SimpleShowLayout>
                            <Divider   />
                        </Box>
                        <Box gridColumn="span 4" >
                            Preview:
                            <PageTemplateSelect/>
                        </Box>
                        <Box gridColumn="span 4">
                            QR Image:
                            <ImageConfigField/>
                        </Box>
                        <Button variant="contained" onClick={handleClose} label={"Back"}/>
                    </Box>
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label="Analytics" path="analytics">
                    {matchAnalytics && <Analytics qrUuid={matchAnalytics.params.id} />}
                </TabbedShowLayout.Tab>
            </TabbedShowLayout>
        </Show>
    )
}




