import {BusinessCardList} from "./BusinessCardList";
// import BusinessCardIcon from "@mui/icons-material/Contacts";
import {BusinessCardShow} from "./BusinessCards";
import {BusinessCardEdit} from "./BusinessCardEdit";
import {BusinessCardCreate} from "./BusinessCardCreate";

export default {
    list: BusinessCardList,
    show: BusinessCardShow,
    create:BusinessCardCreate,
    edit:BusinessCardEdit,
    icon:<img style={{width:"23px"}} src={'https://cdn.scanbuy.com/apps/cmp-v2/images/Contact%20Info-vcard.png'} alt={"vCard Icon"}/>
}
