import * as React from 'react';
import { AppBar, TitlePortal } from 'react-admin';
import { Box, useMediaQuery } from '@mui/material';

// import Logo from './Logo';
import myLogo from '../assets/images/axalta_logo.png';

const CustomAppBar = () => {
    const isLargeEnough = useMediaQuery(theme =>
        theme.breakpoints.up('sm')
    );
    return (
        <AppBar color="secondary" elevation={1}>
            {isLargeEnough && <img src={myLogo} style={{height:"45px"}}/>}
            <TitlePortal  />

            {isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}
        </AppBar>
    );
};

export default CustomAppBar;
