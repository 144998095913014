
import './App.css';
import * as React from 'react';
import {Admin, Resource, ListGuesser, ShowGuesser, CustomRoutes, defaultTheme, EditGuesser} from 'react-admin';
// import { Route } from "react-router-dom";

import dataProvider from './api';
import {authProvider, getIdToken, getToken, handleRedirectCallback, isAuthenticated, login} from "./auth";
import { Layout } from './layout';
import {QrDesignList, QrDesignShow} from "./qrdesigns/QrDesigns";

import QrDesignIcon from "@mui/icons-material/QrCode2";
import {Dashboard} from "./dashboard/Dashboard";

import {i18nProvider} from "./i18n/i18nProvider";

import businesscards from "./businesscards";
import applinks from "./applinks";
import sitelinks from "./sitelinks";
import locations from "./locations";
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import qrpagetemplates from  "./qrpagetemplates"


const lightTheme = {
  ...defaultTheme,
  palette: {
    background: {
      default: '#fafafb',
    },
    secondary: {
      light: '#6ec6ff',
      main: '#006EA4',
      dark: '#0069c0',
      contrastText: '#fff',
    },
  }
};
const darkTheme = {
  ...defaultTheme,
  palette: { mode: 'dark' }
};


// LogRocket.init('oj3ojm/axalta');


LogRocket.init('oj3ojm/axalta', {
  // dom: {
  //   textSanitizer: true,
  //   inputSanitizer: true,
  // },
})
setupLogRocketReact(LogRocket);

const App = () => {
  const [authenticated, setAuthenticated] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const checkAuth = async () => {
      const isAuthenticatedResult = await isAuthenticated();
      setAuthenticated(isAuthenticatedResult);
      setLoading(false);

      // // // This is an example script - don't forget to change it!
      // LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {
      //   name: 'James Morrison',
      //   email: 'jamesmorrison@example.com',
      //
      //   // Add your own custom user variables here, ie:
      //   client: 'axalta'
      // });
    };

    checkAuth();
  }, []);

  React.useEffect(() => {
    const handleAuthRedirect = async () => {
      if (window.location.search.includes('code=')) {
        try {
          await handleRedirectCallback();
          window.history.replaceState({}, document.title, '/');
          setAuthenticated(true);

        } catch (error) {
          console.error(error);
        }
      }
    };

    if(authenticated) {
      getIdToken().then((token) => {
        //console.log(token);
        LogRocket.identify(token.sub, {
              name: token.nickname,
              email: token.email
            }
        )
      })
    } else {
      handleAuthRedirect();
    }
  }, [authenticated]);

  if (loading) {
    return <div>Loading...</div>;
  }


  return (
      <Admin
          title="Axalta QR"
          dataProvider={dataProvider}
          authProvider={authProvider}
          dashboard={Dashboard}
          layout={Layout}
          // i18nProvider={i18nProvider}
          disableTelemetry

          loginPage={LoginPage}
          theme={lightTheme}
          darkTheme={darkTheme}
          defaultTheme="light"

      >
        <Resource name="businesscards" options={{label: "vCard"}} {...businesscards} />
        <Resource name="applinks" options={{label: "App"}} {...applinks} />
        <Resource name="sitelinks" options={{label: "URL"}} {...sitelinks}/>
        <Resource name="qrpagetemplates" options={{label: "Card Templates"}} {...qrpagetemplates} />
        <Resource name="qrdesigns" options={{label: "QR Designs"}} list={QrDesignList} show={QrDesignShow} icon={QrDesignIcon} />
        {/*Page Templates*/}

        <Resource name="locations" options={{label: "Locations"}} {...locations}   recordRepresentation="Location"/>
        <Resource name="countries" options={{label: "Countries"}} list={ListGuesser} show={ShowGuesser}   recordRepresentation="Country"/>

      </Admin>
  );
};

const LoginPage = () => {
  const handleLogin = async () => {
    await login();
  };

  return (
      <div>
        <h1>Login</h1>
        <button onClick={handleLogin}>Log In</button>
      </div>
  );
};

export default App;



