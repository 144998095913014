import React, {useCallback} from "react";
import {Card, CardBody, CardFooter, CardText, Col, Container, Row,} from "reactstrap";
import {observer} from "mobx-react-lite";


const VCard = observer(({viewState}) => {
    return (
        <Container style={{border:"1", borderStyle:"dashed", paddingBottom: "30px"}}>
            <Row>
                <Col>
                    <Card style={
                        viewState.styles.primaryVCardContainer
                        }>
                        <CardBody  style={{
                            padding: "0"
                        }}>
                            <CardText>
                                {viewState.profilePicture ? <img
                                    style={ viewState.styles.imageVCardContainer ? viewState.styles.imageVCardContainer : {width:'128px', height: '128px'}}
                                    src={viewState.profilePicture}/> : ''}<br/>
                               <p style={viewState.styles.vcardNameText}> {viewState.firstName ? <>{viewState.firstName}</> : ''} {viewState.lastName ? <>{viewState.lastName}<br/></> : null}</p>
                                   {viewState.jobTitle ? <>{viewState.jobTitle}<br/></> : null}
                                {viewState.jobRole ? <>{viewState.jobRole}<br/></> : null}
                                {viewState.organization ? <>{viewState.organization}<br/></> : null}
                                </CardText>
                            <CardText style={viewState.styles.secondaryVCardContainer}>
                                <p style={viewState.styles.phoneNumberText}>
                                {viewState.cellNumber() ? <>Mobile: <a href={"tel:"+viewState.cellNumber()}>{viewState.cellNumber()}</a><br/></> : null}
                                {viewState.workNumber() ? <>Work: <a href={"tel:"+viewState.workNumber()}>{viewState.workNumber()}</a><br/></> : null}
                                {viewState.homeNumber() ? <>Home Number: <a href={"tel:"+viewState.homeNumber()}>{viewState.homeNumber()}</a><br/></> : null}
                                {viewState.faxNumber() ? <>Fax Number: <a href={"tel:"+viewState.faxNumber()}>{viewState.faxNumber()}</a><br/></> : null}
                                </p>
                            </CardText>

                            {viewState.email() && viewState.secondaryEmail() ? <>
                                    <CardText style={viewState.styles.secondaryVCardContainer}>
                                        Primary Email: <a href={"mailto:"+viewState.email()}>{viewState.email()}</a><br/>
                                        Secondary Email: <a href={"mailto:"+viewState.secondaryEmail()}>{viewState.secondaryEmail()}</a>
                                    </CardText>
                                </> :
                                <CardText style={viewState.styles.secondaryVCardContainer}>
                                    <a href={"mailto:"+viewState.email()}>{viewState.email()}</a>
                                    <a href={"mailto:"+viewState.secondaryEmail()}>{viewState.secondaryEmail()}</a>
                                </CardText>
                            }
                            <CardText style={viewState.styles.secondaryVCardContainer}>
                                {viewState.website ? <><a href={viewState.website}>{viewState.website}</a></> : null}
                            </CardText>
                            {viewState.workAddress().addressType && viewState.homeAddress().addressType? <>
                                    <CardText style={viewState.styles.secondaryVCardContainer}>
                                        <p style={viewState.styles.subtitleText} >Office:</p>
                                        {viewState.workAddress().street ? <> {viewState.workAddress().street}<br/></> : null}
                                        {viewState.workAddress().city ? <>{viewState.workAddress().city},&nbsp;</> : null}
                                        {viewState.workAddress().state ? <>{viewState.workAddress().state}&nbsp;</> : null}
                                        {viewState.workAddress().zipcode ? <>{viewState.workAddress().zipcode}<br/></> : null}
                                        {viewState.workAddress().country ? <>{viewState?.workAddress().country}</> : null}
                                    </CardText>
                                    <CardText style={viewState.styles.secondaryVCardContainer}>
                                       <p style={viewState.styles.subtitleText}> Home:</p>
                                        {viewState.homeAddress().street ? <> {viewState.homeAddress().street}<br/></> : null}
                                        {viewState.homeAddress().city ? <>{viewState.homeAddress().city},&nbsp;</> : null}
                                        {viewState.homeAddress().state ? <>{viewState.homeAddress().state}&nbsp;</> : null}
                                        {viewState.homeAddress().zipcode ? <>{viewState.homeAddress().zipcode}<br/></> : null}
                                        {viewState.homeAddress().country ? <>{viewState?.homeAddress().country}</> : null}
                                    </CardText></> :

                                <CardText style={viewState.styles.secondaryVCardContainer}>
                                    {viewState.workAddress().street ? <> {viewState.workAddress().street}<br/></> : null}
                                    {viewState.workAddress().city ? <>{viewState.workAddress().city},&nbsp;</> : null}
                                    {viewState.workAddress().state ? <>{viewState.workAddress().state}&nbsp;</> : null}
                                    {viewState.workAddress().zipcode ? <>{viewState.workAddress().zipcode}<br/></> : null}
                                    {viewState.workAddress().country ? <>{viewState?.workAddress().country}</> : null}
                                    {viewState.homeAddress().street ? <> {viewState.homeAddress().street}<br/></> : null}
                                    {viewState.homeAddress().city ? <>{viewState.homeAddress().city},&nbsp;</> : null}
                                    {viewState.homeAddress().state ? <>{viewState.homeAddress().state}&nbsp;</> : null}
                                    {viewState.homeAddress().zipcode ? <>{viewState.homeAddress().zipcode}<br/></> : null}
                                    {viewState.homeAddress().country ? <>{viewState?.homeAddress().country}</> : null}
                                </CardText>
                            }

                            <CardText style={viewState.styles.secondaryVCardContainer}>
                            {viewState.birthday ? <>Birthday: {viewState.birthday}<br/></> : null}
                            {viewState.notes ? <>Notes: {viewState.notes}<br/></> : null}
                            </CardText>
                        </CardBody>


                        <CardFooter style={viewState.styles.downloadVCardButtonContainer}>
                            <a
                               style={viewState.styles.downloadVCardButtonText}
                               href={viewState.downloadLink}
                               download="vcard.vcf">Download</a>
                            </CardFooter>
                    </Card>

                </Col>
            </Row>
        </Container>
    )
});

export default VCard;
