import { fetchUtils } from 'react-admin';
import { getToken } from './auth';
import { stringify } from 'query-string';
import config from "./configs";
import rawData from "./locations/data.json";
import lodash from "lodash";




export const apiUrl = config.API_DOMAIN_NAME



export const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const tokenPromise = getToken();
  return tokenPromise.then((token) => {
    options.headers.set('Authorization', `Bearer ${token}`);
    options.headers.set('x-sb-context', config.TENANT_UUID);
    return fetchUtils.fetchJson(url, options);
  });
};

const dataProvider = {

  getList: (resource, params) => {

    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    let query = {
      page: page, // (page - 1) * perPage,
      perPage: perPage,
      sort:  field,
      order: order,
    };
    if (params.filter) {
      query = {
        ...query,
        ...params.filter
      }
      // query.q = JSON.stringify(params.filter);
    }

    if(resource ==='businesscards'){
      resource = 'qr/codes';
      query.type = 'vcard';
      if(params.filter && params.filter.q){
        query.q = params.filter.q;
      }
    } else if(resource ==='sitelinks'){
      resource = 'qr/codes';
      query.type = 'url';
      if(params.filter && params.filter.q){
        query.name = params.filter.q;
      }
    } else if(resource ==='applinks'){
      resource = 'qr/codes';
      query.type = 'appLink';
      if(params.filter && params.filter.q){
        query.name = params.filter.q;
      }
    } else if(resource ==='qrdesigns'){
      resource = 'qr/image/configs';
    } else if(resource === 'qrpagetemplates') {
      resource = 'qr/pagetemplates';
      query.pageType = 'vcard';
    } else if (resource === 'locations'){
      console.log("locations, get list ", params);
      let rawData = require('./locations/data.json');
      let md5 = require('md5');

      rawData = rawData.map((x) => ({ ...x, id: md5(x.Location) })  );

      if(params.sort ){

        rawData = lodash.orderBy(rawData, [params.sort.field],lodash.lowerCase(params.sort.order));

      }

      return new Promise((resolve, reject) => {

        if(params.id) {
          return resolve({
            data: rawData.find((x) => x.id === params.id)
          });
        }

        if(params.filter && params.filter.country){
          rawData = rawData.filter((x) => x.Country === params.filter.country);
        }

        resolve({
          data: rawData,
          total: rawData.length,
        });
      });

    } else if (resource === 'countries'){
      console.log("countries", params);
      let rawData = require('./countries/data.json');
      let md5 = require('md5');

      rawData = rawData.map((x) => ({ ...x, id: (x.Country) })  );

      return new Promise((resolve, reject) => {

        if(params.id) {
          return resolve({
            data: rawData.find((x) => x.id === params.id)
          });
        }


        resolve({
          data: rawData,
          total: rawData.length,
        });
      });

    }

    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ json }) => ({
      data: json.results.map((x) => ({ ...x, id: x.uuid })  ),
      total: json._meta.itemCount,
    }));
  },

  getOne: (resource, params) => {

    if(resource ==='businesscards'){
      resource = 'qr/codes';
    } else if(resource ==='sitelinks'){
      resource = 'qr/codes';
    } else if(resource ==='applinks'){
      resource = 'qr/codes';
    } else if(resource ==='qrdesigns'){
      resource = 'qr/image/configs';
    } else if(resource === 'qrpagetemplates') {
      resource = 'qr/pagetemplates'
    }
    else if (resource === 'locations'){
      return new Promise((resolve, reject) => {
        let rawData = require('./locations/data.json');
        let md5 = require('md5');

        rawData = rawData.map((x) => ({ ...x, id: md5(x.Location) })  );

        resolve({
          data: rawData.find((x) => x.id === params.id)});
      });
    }

    return httpClient(`${apiUrl}/${resource}/${params.id}`).then(({json}) => ({
      data: {...json, id: json.uuid},
    }))
  },

  create: (resource, params) => {

    if(resource ==='businesscards'){
      resource = 'qr/codes';
      params.data.name = "Business Card ("+ params.data.data.firstName + " " + params.data.data.lastName + ") - " +Date.now();
      params.data.type = 'vcard';
      params.data.status = 'PUBLISHED';
      params.data.statusDetail = {
        publishOnUpdate: true
      };
      params.data.data.emails[0].emailType = 'Primary';
      params.data.data.phoneNumbers[0].phoneType = 'Work';

      params.data.shortUrl = {domain: "http://scb.ai"}
    } else if(resource ==='sitelinks'){
      resource = 'qr/codes';
      params.data.type = 'url';
      params.data.status = 'PUBLISHED';
      params.data.statusDetail = {
        publishOnUpdate: true
      };
      params.data.shortUrl = {domain: "http://scb.ai"}
    } else if(resource ==='applinks'){
      resource = 'qr/codes';
      params.data.type = 'appLink';
      params.data.status = 'PUBLISHED';
      params.data.statusDetail = {
        publishOnUpdate: true
      };
      params.data.shortUrl = {domain: "http://scb.ai"}
    } else if(resource === 'qrpagetemplates') {
      resource = 'qr/pagetemplates';
    }

    return httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({json}) => ({
      data: {...params.data, id: json.uuid},
    }))
  },
  update: (resource, params) => {

    if(resource ==='businesscards'){
      resource = 'qr/codes';
      // params.data.status = 'PUBLISHED';
    } else if(resource ==='sitelinks'){
      resource = 'qr/codes';
      params.data.type = 'url';
    } else if(resource ==='applinks'){
      resource = 'qr/codes';
      params.data.type = 'applinks';
    } else if(resource === 'qrpagetemplates') {
      resource = 'qr/pagetemplates';
    }
    delete params.data.tags;

    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PATCH',
      body: JSON.stringify(params.data),
    }).then(({json}) => ({
      data: {...params.data, id: json.uuid},
    }))
  },

  delete: (resource, params) => {
    if(resource ==='businesscards'){
      resource = 'qr/codes';
    } else if(resource ==='sitelinks'){
      resource = 'qr/codes';
    } else if(resource ==='applinks'){
      resource = 'qr/codes';
    }else if(resource === 'qrpagetemplates') {
      resource = 'qr/pagetemplates';
    }

    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(() => ({
      data: {id: params.id},
    }))
  },

  deleteMany: (resource, params) => {

    if(resource ==='businesscards'){
      resource = 'qr/codes';
    } else if(resource ==='sitelinks'){
      resource = 'qr/codes';
    } else if(resource ==='applinks'){
      resource = 'qr/codes';
    }else if(resource === 'qrpagetemplates') {
      resource = 'qr/pagetemplates';
    }

    const ids = params.ids || [];

    const promises = ids.map((id) => httpClient(`${apiUrl}/${resource}/${id}`, {method: 'DELETE'}));

    return Promise.all(promises).then(() => ({data: ids}));

    // return httpClient(`${apiUrl}/${resource}/${params.id}`, {
    //   method: 'DELETE',
    // }).then(() => ({
    //   data: {id: params.id},
    // }))

  },

  // Additional API methods for react-admin

  getMany: (resource, params) => {

    const query = {
      id: params.ids,
    };

    if(resource ==='businesscards'){
      resource = 'qr/codes';
      query.type = 'vcard';
    } else if(resource ==='sitelinks'){
      resource = 'qr/codes';
      query.type = 'url';
    } else if(resource ==='applinks'){
      resource = 'qr/codes';
      query.type = 'applinks';
    } else if(resource ==='qrdesigns'){
      resource = 'qr/image/configs';

    } else if(resource === 'qrpagetemplates') {
      resource = 'qr/pagetemplates';
    }
    else if (resource === 'locations'){
      console.log("locations" + JSON.stringify(params));
      let rawData = require('./locations/data.json');
      let md5 = require('md5');

      rawData = rawData.map((x) => ({ ...x, id: md5(x.Location) })  );

      return new Promise((resolve, reject) => {

        if(params.id) {
          return resolve({
            data: rawData.find((x) => x.id === params.id)
          });
        }


        resolve({
          data: rawData,
          total: rawData.length,
        });
      });

    } else if (resource === 'countries'){
      console.log("countries" + JSON.stringify(params));
      let rawData = require('./countries/data.json');
      let md5 = require('md5');
      rawData = rawData.map((x) => ({ ...x, id: (x.Country) })  );
      return new Promise((resolve, reject) => {
        if(params.id) {
          return resolve({
            data: rawData.find((x) => x.id === params.id)
          });
        }

        resolve({
          data: rawData,
          total: rawData.length,
        });
      });

    }


    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ json }) => ({
      data: json.results,
    }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      skip: (page - 1) * perPage,
      limit: perPage,
      sort: `${field}:${order}`,
      [params.target]: params.id,
    };

    // if(resource ==='qrdesigns'){
    //   resource = 'qr/image/configs';
    // }

    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ json }) => ({
      data: json.results.map((x) => ({ ...x, id: x.uuid })  ),
      total: json._meta.itemCount,
    }));
  },
};

export default dataProvider;

