import {AppLinkList} from "./ApplinkList";
import {ApplinkEdit} from "./ApplinkEdit";
import {AppLinkCreate} from "./ApplinkCreate";
import {ApplinkShow} from "./ApplinkShow";


export default {
    list: AppLinkList,
    show: ApplinkShow,
    create:AppLinkCreate,
    edit:ApplinkEdit,
    icon:<img style={{width:"23px"}} src={"https://cdn.scanbuy.com/apps/cmp-v2/images/App.png"}/>
}
