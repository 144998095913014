import {EditBase, SimpleForm, TextInput, useUnique} from "react-admin";
import * as React from "react";


export const SitelinkEdit = ({ onCancel, ...props }) => {

    const unique = useUnique();
    const required = (message = 'Required') =>
        value => value ? undefined : message;
    const checkIfUrl = (message = 'Must be a valid url') =>
        value => value.match("^https?:\\/\\/(?:www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b(?:[-a-zA-Z0-9()@:%_\\+.~#?&\\/=]*)$") ? undefined : message;

    const validateName = [required()] //,unique({ message: 'Must be a unique name' }) //TODO: fix , for 'update' it will always fail
    const urlValidator = [required(),checkIfUrl()]
    return (
        <EditBase {...props}>
            <SimpleForm warnWhenUnsavedChanges>
                <TextInput source="name" style={{width:'50%'}} validate={validateName}/>
                <TextInput source="data.url" label="Url" style={{width:'50%'}} validate={urlValidator}/>
            </SimpleForm>

        </EditBase>
    );
}

