import * as React from "react";
import {
    BulkDeleteButton, CreateButton,
    Datagrid,
    DateField,
    EditButton, ExportButton,
    List,
    TextField,
    TopToolbar,
} from "react-admin";
import {Box} from "@mui/material";


const ListActions = () => (
    <TopToolbar>
        <CreateButton/>
        <ExportButton/>
    </TopToolbar>
);



export const PageTemplatesList = () => {
    return (
        <Box>
            <List actions={<ListActions/>}>
                <Datagrid rowClick="show">
                    <TextField source="name" label="Name"/>
                    <TextField source="pageType" label="Page Type"/>
                    <DateField source="updatedAt"/>
                    <DateField source="createdAt"/>
                    <EditButton/>
                </Datagrid>
            </List>
        </Box>
    )
}