import {
    CreateButton,
    Datagrid,
    DateField, DateInput, DeleteButton, downloadCSV, EditButton,
    ExportButton,
    FilterButton,
    List, SearchInput, SelectInput,
    TextField,
    TopToolbar,
    UrlField
} from "react-admin";
import * as React from "react";
import {ShortUrl} from "./Sitelinks";
import jsonExport from 'jsonexport/dist';
import {LinkIcon} from "../applinks/utils";


const ListActions = () => (
    <TopToolbar>
        <FilterButton/>
        <ExportButton/>
        <CreateButton/>
        {/* Add your custom actions */}
        {/*<Button*/}
        {/*    onClick={() => { alert('Your custom action'); }}*/}
        {/*    label="Show calendar"*/}
        {/*>*/}
        {/*    <IconEvent/>*/}
        {/*</Button>*/}
    </TopToolbar>
);

const sitelinkFilters = [
    <SearchInput source="q" alwaysOn />,
    <SelectInput
        name="status"
        source="status"
        choices={[
            { id: 'DRAFT', name: 'DRAFT' },
            { id: 'PUBLISHED', name: 'PUBLISHED' }
        ]}
    />,
    <DateInput name="updatedAt" source="updatedAt" />,
    <DateInput name="createdAt" source="createdAt" />,
];

const exporter = urls => {
    const urlsForExport = urls.map(url => {
        const {  owner,_id,uuid,name,
            id,publishOnUpdate,tags,
            locationId,countryId,emailType,
            domain,uri,type,data,createdAt,updatedAt,
            shortUrl,statusDetail,dataDetail,status,_audit,_tenantUuid,
            images,rules,utm,

            ...urlForExport } = url;

        urlForExport.name = url.name ?? "";
        urlForExport.url = url.data.url ?? "";
        urlForExport.short_url = (url?.shortUrl?.domain && url?.shortUrl?.uri) ?
            url?.shortUrl?.domain + "/" + url?.shortUrl?.uri : "";
        urlForExport.status = url.status ?? ""
        urlForExport.updated_at = url?.updatedAt ? new Date(url?.updatedAt):"";
        urlForExport.created_at = url?.createdAt ? new Date(url?.createdAt):"";

        return urlForExport;
    });
    jsonExport(urlsForExport, {
        headers: ['name',"url","short_url","status","updated_at","created_at"]
    }, (err, csv) => {
        downloadCSV(csv, 'axalta_links'); //  <-- name download file here
    });
};


export const SiteLinkList = () => (
    <List
        exporter={exporter}
        filters={sitelinkFilters}
        actions={<ListActions/>}

    >
        <Datagrid rowClick="show" optimized>
            <TextField source="name" />
            <UrlField source="data.url" label="Url"/>
            <ShortUrl source="shortUrl" label="Short Url"/>
            <TextField source="status" />
            <DateField source="updatedAt" />
            <DateField source="createdAt" />
            <EditButton/>
            <DeleteButton mutationMode="pessimistic"  />
        </Datagrid>
    </List>
)
