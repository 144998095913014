import {
    ArrayField,
    BooleanField, Button, CreateButton,
    Datagrid,
    DateField, FilterButton,
    List, ReferenceField,
    Show,
    SimpleShowLayout, TabbedShowLayout,
    TextField, TextInput,
    TopToolbar, UrlField, useRedirect
} from "react-admin";

import {useRecordContext} from "react-admin";
import * as React from "react";
import {Box, Divider} from "@mui/material";
import {ImageConfigField} from "../qrdesigns/ImageConfigField";

export const ShortUrl = ({source}) => {
    const record = useRecordContext();
    if (!record) return null;
    const url = record[source].domain + "/" + record[source].uri
    return <>{url}</>;
};


export const SiteLinkShow = () => {
    const redirect = useRedirect();
    const record = useRecordContext();
    console.log(record)
    const handleClose = () => {
        redirect('list', 'sitelinks');
    };

    return (
        <Show title={"URL"}>
            <TabbedShowLayout>
                <TabbedShowLayout.Tab label="Details">

                    <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                        <Box gridColumn="span 8">
                            <SimpleShowLayout>
                                <TextField source="name"/>
                                <UrlField source="data.url" label="Url"/>
                                <ShortUrl source="shortUrl" label="Short Url"/>

                                <TextField source="status" style={{width:'50%'}}/>
                                <DateField source="updatedAt" style={{width:'50%'}}/>


                            </SimpleShowLayout>
                            <Divider/>
                            <br/>
                            <Button variant="contained" onClick={handleClose} label={"Back"}/>
                        </Box>
                        <Box gridColumn="span 4">
                            <ImageConfigField/>
                        </Box>
                    </Box>
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label="Analytics" path="analytics">
                    <h1>Coming Soon..</h1>
                    <div id={"superset-analytics"}></div>
                </TabbedShowLayout.Tab>
            </TabbedShowLayout>
        </Show>
    )
};
